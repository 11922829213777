import React, { useState, useEffect } from "react";
import { Redirect } from "react-router-dom";
import { isAuthorized } from "../../system/helpers/localstorageHelper";
import { APP_ROUTES } from "../../system/constants/constantsUrl";

export const useRedirectionRoute = (check) => {
  const [redirect, setRedirect] = useState<React.ReactNode | null>();
  const handleRedirection = (condition, route) => {
    if (condition) setRedirect(<Redirect to={route} />);
  };
  useEffect(() => {
    switch (check) {
      case "isLogged": {
        handleRedirection(isAuthorized(), APP_ROUTES.dashboard.general);
        break;
      }
      default:
        setRedirect(null);
    }
  }, []);
  return [redirect];
};
