import React from "react";

interface HowItWorksSliderItemProps {
  info: {
    title: string;
    icon: string;
    text: string;
  };
}

const HowItWorksSliderItem: React.FC<HowItWorksSliderItemProps> = ({
  info,
}) => {
  const { title, text, icon } = info;
  return (
    <div className="how-it-works-slider-item">
      <div className="how-it-works-slider-item__icon">
        <img src={icon} alt="" />
      </div>
      <div className="how-it-works-slider-item__info">
        <p className="how-it-works-slider-item__title">{title}</p>
        <p className="how-it-works-slider-item__text">{text}</p>
      </div>
    </div>
  );
};

export default HowItWorksSliderItem;
