import axiosClient from "../../../../system/helpers/axiosConfig";
import {
  GetNewsRequest,
  GetPostRequest,
  NewsResponse,
  PostResponse,
} from "../utils";

export const getNews = (params: GetNewsRequest) => {
  return axiosClient
    .get<NewsResponse>(`/api/news`, { params })
    .then((r) => r.data);
};

export const getPost = (params: GetPostRequest) => {
  return axiosClient
    .get<PostResponse>(`/api/news-content`, { params })
    .then((r) => r.data);
};
