import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { loginByIdHelper } from "../../../../../system/helpers/localstorageHelper";
import * as actionTypes from "../../utils";
import { getUserBasic } from "../../../../../modules/User/actions";
import { Spin } from "antd";
import { _t } from "../../../../../modules/Common/components/InjectIntlContext";

const LoginByUserId = ({ userData, activateHook }) => {
  const dispatch = useDispatch();

  const [loginAs, { isLoading, error }] = activateHook({
    onSuccess: (res) => {
      loginByIdHelper();
      dispatch({ type: actionTypes.AUTH_SUCCESS, payload: res.data });
      dispatch(getUserBasic({ user_id: res.data.user_id }));
    },
  });
  useEffect(() => {
    if (userData) {
      loginAs(userData);
    }
  }, [userData]);

  return (
    <div className="auth-box">
      {!error && userData && (
        <Spin spinning={isLoading}>
          <p className="auth-box__sub-title">
            {_t("Authentication by ID in progress, please wait...")}
          </p>
        </Spin>
      )}
      {error && (
        <p className="auth-box__sub-title">{_t("Something went wrong")}</p>
      )}
      {!userData && (
        <p className="auth-box__sub-title">
          {_t("Url is not valid, please contact your admin")}
        </p>
      )}
    </div>
  );
};

export default LoginByUserId;
