import React from "react";
import { useParams } from "react-router-dom";
import { OuterPageTemplate, SectionTemplate } from "../../../modules/Layouts";
import { getLocale } from "../../../system/helpers/localstorageHelper";
import { useTournamentsShow } from "./api";
import { Spin } from "antd";
import { TournamentContent } from "./components";

const TournamentArticle = () => {
  const { id } = useParams();
  const locale = getLocale();
  const { data, isLoading } = useTournamentsShow({
    language: locale,
    id: id,
  });

  return (
    <OuterPageTemplate>
      <SectionTemplate sectionName="tournaments" className="top-page-indent">
        <Spin spinning={isLoading}>
          {data && (
            <TournamentContent
              tournament={data?.tournament}
              prizes={data?.prizes}
              results={data?.results}
            />
          )}
        </Spin>
      </SectionTemplate>
    </OuterPageTemplate>
  );
};

export default TournamentArticle;
