import React from "react";
import { _t } from "../../../../modules/Common/components/InjectIntlContext";
import classNames from "classnames";


const FaqNav = ({ data, setFilter , isLoading }) => {
  const faqItems = (_t: any) => [
    {
      title: _t("general"),
      selected: data?.current_category === "general",
      requestParam: "general",
    },
    {
      title: _t("financial"),
      selected: data?.current_category === "finance",
      requestParam: "finance",
    },
    {
      title: _t("technical"),
      selected: data?.current_category === "technical",
      requestParam: "technical",
    },
  ];
  const faqItemsTranslated = faqItems(_t);

  return (
    <ul className="faq-nav">
      {faqItemsTranslated.map((item, idx) => (
        <li
          key={idx}
          className={classNames("faq-nav__item", {
            "faq-nav__item--active": item.selected,
          })}
        >
          <button
            onClick={() => setFilter(item.requestParam)}
            className="faq-nav__btn"
            disabled={isLoading}
          >
            {item.title}
          </button>
        </li>
      ))}
    </ul>
  );
};

export default FaqNav;
