import { notify } from "../../common/helpers";

export const requestStatuses = (types) => {
  let statuses = {
    start: "",
    success: "",
    error: "",
  };

  Object.keys(statuses).forEach((item, idx) => {
    if (Array.isArray(types)) {
      statuses[item] = types[idx];
    } else {
      statuses[item] = `${types}_${item.toUpperCase()}`;
    }
  });

  return statuses;
};

export const validateRequest = (config) => {
  if (!config.hasOwnProperty("url") || !config.hasOwnProperty("method")) {
    throw new Error("Do not valid request config");
  }
};

export const responseNotify = (type, notifyMessage) => {
  if (type && notifyMessage && notifyMessage[type]) {
    notify(type, notifyMessage[type]);
  }
};

export const responseFile = (response, filename) => {
  if (response.data) {
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", filename);
    document.body.appendChild(link);
    link.click();
  }
};
