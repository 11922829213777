import React, { useState } from "react";
import { OuterPageTemplate, SectionTemplate } from "../../../modules/Layouts";
import { _t } from "../../../modules/Common/components/InjectIntlContext";
import { PresentationNav } from "./components";
import PresentationContainer from "./components/PresentationContainer/PresentationContainer";
import { ContactBox } from "../components";
import { useMediaQuery } from "react-responsive";
import { Link } from "react-router-dom";
import { APP_ROUTES } from "../../../system/constants/constantsUrl";

const Presentation = () => {
  const [activeTab, setActiveTab] = useState<number | undefined>(0);
  const mdResolution = useMediaQuery({ query: "(max-width: 991px)" });

  return (
    <OuterPageTemplate>
      <SectionTemplate
        sectionName="presentation-section"
        className="top-page-indent"
      >
        <h1 className="page-title page-title--center">
          {_t("presentation_title")}
        </h1>
        <div className="presentation-section__info">
          <p className="presentation-section__text">
            {_t("presentation_text")}
          </p>
          <Link to={APP_ROUTES.contacts} className="income-button">
            {_t("presentation_btn")}
          </Link>
        </div>
        <PresentationNav activeTab={activeTab} setActiveTab={setActiveTab} />
        <PresentationContainer activeTab={activeTab} />
      </SectionTemplate>

      <SectionTemplate sectionName="contact-section">
        {mdResolution && (
          <h2 className="section-title">{_t("contacts_welcome_title")}</h2>
        )}
        <ContactBox />
      </SectionTemplate>
    </OuterPageTemplate>
  );
};

export default Presentation;
