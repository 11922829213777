import React from "react";
import { APP_ROUTES } from "../../../../system/constants/constantsUrl";
import { _t } from "../../../../modules/Common/components/InjectIntlContext";
import { Col, Row } from "antd";
import { CommissionsListItem } from "../CommissionsListItem";

const commissions = (_t: any) => [
  {
    title: _t("revshare_commission"),
    path: APP_ROUTES.commissions.revshare,
    description: _t("revshare_commission_desc"),
  },
  {
    title: _t("cpa_program"),
    path: APP_ROUTES.commissions.cpa,
    description: _t("cpa_program_desc"),
  },
  {
    title: _t("subaffiliate_program"),
    path: APP_ROUTES.commissions.subaffiliate,
    description: _t("subaffiliate_program_desc"),
  },
];

const CommissionsList = () => {
  const commissionsTranslated = commissions(_t);

  return (
    <Row className="commissions-list" gutter={40}>
      {commissionsTranslated.map((item, idx) => (
        <Col key={idx} lg={8} span={24}>
          <CommissionsListItem
            title={item.title}
            text={item.description}
            path={item.path}
          />
        </Col>
      ))}
    </Row>
  );
};

export default CommissionsList;
