import React from "react";
import { OuterPageTemplate, SectionTemplate } from "../../../modules/Layouts";
import { _t } from "../../../modules/Common/components/InjectIntlContext";
import { TestimonialsList } from "./components";

const Testimonials = () => {
  return (
    <OuterPageTemplate>
      <SectionTemplate sectionName="testimonials-page" className='top-page-indent' containerSize='small'>
        <h1 className="page-title">{_t("testimonials")}</h1>
        <TestimonialsList />
      </SectionTemplate>
    </OuterPageTemplate>
  );
};

export default Testimonials;
