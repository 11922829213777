import React, { useState, useEffect } from "react";
import { DatePicker as AntDatePicker, Space } from "antd";
import { CalendarOutlined } from "@ant-design/icons";
import moment, { Moment } from "moment";
import { ADDITIONAL_CURRENCY_SYMBOL_DATE } from "../../../system/config";

type Value = { from: Moment | null; to: Moment | null };

export function DatePicker({
  onChange,
  value,
  clearable = false,
  hasDisabledDate,
}: {
  value?: Value;
  onChange?: (value: Value) => any;
  clearable?: boolean;
  hasDisabledDate?: boolean;
}) {
  const [from, setFrom] = useState(value?.from);
  const [to, setTo] = useState(value?.to);

  useEffect(() => {
    onChange({
      from,
      to,
    });
  }, [from, to]);

  const handleDisabledDate = (currentDate) => {
    if (from < moment(ADDITIONAL_CURRENCY_SYMBOL_DATE)) {
      return (
        currentDate &&
        currentDate >
          moment(ADDITIONAL_CURRENCY_SYMBOL_DATE)
            .subtract(1, "days")
            .endOf("day") &&
        currentDate < from
      );
    }
  };
  return (
    <div className="datepicker">
      <Space>
        <AntDatePicker
          key={1}
          value={from}
          onChange={setFrom as any}
          showToday={false}
          dropdownClassName="datepicker-dropdown"
          suffixIcon={null}
          allowClear={clearable}
        />
        <CalendarOutlined />
        <AntDatePicker
          key={2}
          value={to}
          onChange={setTo as any}
          showToday={false}
          dropdownClassName="datepicker-dropdown"
          suffixIcon={null}
          allowClear={clearable}
          disabledDate={handleDisabledDate}
        />
      </Space>
    </div>
  );
}
