import React from "react";
import { _t } from "../../../../modules/Common/components/InjectIntlContext";
import { APP_ROUTES } from "../../../../system/constants/constantsUrl";
import { NavLink } from "react-router-dom";

const tabsItems = (_t: any) => [
  {
    title: _t("sign_in"),
    path: APP_ROUTES.login.main
  },
  {
    title: _t("sign_up"),
    path: APP_ROUTES.registration.main,
  },
];
const AuthTabs = () => {
  const tabsItemsTranslated = tabsItems(_t);

  return (
    <ul className="auth-nav">
      {tabsItemsTranslated.map((item, idx) => (
        <li key={idx} className="auth-nav__item">
          <NavLink
            to={item.path}
            activeClassName="auth-nav__link--active"
            className="auth-nav__link"
          >
            {item.title}
          </NavLink>
        </li>
      ))}
    </ul>
  );
};

export default AuthTabs;
