import React from "react";
import { useTestimonials } from "../../Testimonials/api";
import { getLocale } from "../../../../system/helpers/localstorageHelper";
import { Col, Row, Spin } from "antd";
import { TopTestimonialItem } from "../TopTestimonialItem";
import Slider, { Settings } from "react-slick";
import { useMediaQuery } from "react-responsive";

const settings: Settings = {
  speed: 200,
  arrows: false,
  dots: false,
  variableWidth: true,
  infinite: true,
};

const TopTestimonials = () => {
  const mdResolution = useMediaQuery({ query: "(max-width: 991px)" });
  const { data, isLoading } = useTestimonials({ language: getLocale() });
  return (
    <>
      <Spin spinning={isLoading}>
        {!mdResolution && (
          <Row gutter={24}>
            {data?.testimonials.slice(0, 3).map((item) => (
              <Col key={item.id} span={24} lg={8}>
                <TopTestimonialItem info={item} />
              </Col>
            ))}
          </Row>
        )}
        {mdResolution && (
          <Slider {...settings}>
            {data?.testimonials.slice(0, 3).map((item) => (
              <TopTestimonialItem info={item} key={item.id} />
            ))}
          </Slider>
        )}
      </Spin>
    </>
  );
};

export default TopTestimonials;
