import React from "react";
import { Faq } from "../api";

interface FAQItemProps {
  info: Faq;
}

const FAQItem: React.FC<FAQItemProps> = ({ info }) => {
  const replaceText = (text) => {
    return text?.replace(/<[^>]+>/g, "");
  };

  return (
    <div className="faq-item">
      <h3
        className="faq-item__title"
        dangerouslySetInnerHTML={{
          __html: replaceText(info.translations[0]?.question),
        }}
      ></h3>
      <p
        className="faq-item__text"
        dangerouslySetInnerHTML={{
          __html: replaceText(info.translations[0]?.answer),
        }}
      ></p>
    </div>
  );
};

export default FAQItem;
