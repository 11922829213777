import React from "react";
import { useTestimonials } from "../api";
import { getLocale } from "../../../../system/helpers/localstorageHelper";
import { TestimonialItem } from "./index";
import {Spin} from "antd";

const TestimonialsList = () => {
  const { data, isLoading } = useTestimonials({ language: getLocale() });
  return (
    <Spin spinning={isLoading}>
      <div className="testimonial-list">
        {data?.testimonials.map((item) => (
          <TestimonialItem
            title={item.title}
            content={item.content}
            link={item.url}
            key={item.id} />
        ))}
      </div>
    </Spin>
  );
};

export default TestimonialsList;
