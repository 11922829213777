import React from "react";
import { Form } from "antd";
import { _t } from "../../../../modules/Common/components/InjectIntlContext";
import Input from "antd/lib/input";
import { IncomeButton } from "../IncomeButton";
import { useContactsSend } from "./hooks";

const ContactForm = () => {
  const [sendContacts, { isLoading }] = useContactsSend();
  const [form] = Form.useForm();

  const submitForm = (values) => {
    sendContacts(values)
    form.resetFields();
  }

  return (
    <Form
      className="contact-form"
      onFinish={submitForm}
      form={form}
    >
      <Form.Item
        name="text"
        className="contact-form__item"
        rules={[
          {
            required: true,
            message: _t("valid_contacts_msg"),
          },
        ]}
      >
        <Input
          placeholder={"Skype / Telegram / Mail "}
          className="income-input"
        />
      </Form.Item>
      <Form.Item
        name="name"
        className="contact-form__item"
        rules={[
          {
            required: true,
            message: _t("input_name"),
          },
        ]}
      >
        <Input placeholder={_t("Name")} className="income-input" />
      </Form.Item>

      <IncomeButton
        type="submit"
        text={_t("Send")}
        className="contact-form__btn"
        disabled={isLoading}
      />
    </Form>
  );
};

export default ContactForm;
