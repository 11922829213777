import React from "react";
import { Link } from "react-router-dom";
import { _t } from "../../../../modules/Common/components/InjectIntlContext";

interface MorePageItemProps {
  title: string;
  path: string;
  idx: number;
}

const MorePageItem: React.FC<MorePageItemProps> = (props) => {
  const { title, path, idx } = props;
  return (
    <div className={`more-page-item more-page-item--${idx}`}>
      <p className="more-page-item__title">{title}</p>
      <Link to={path} className='income-button income-button--dark more-page-item__btn'>{_t("show_more")}</Link>
      <div className="more-page-item__icon"/>
    </div>
  );
};

export default MorePageItem;
