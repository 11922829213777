import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import { Header } from "./components";
import { Footer } from "./components/Footer";
import { ContactsNav } from "../../pages/outer/components";

interface OuterPageTemplateProps {
  children?: React.ReactNode;
}

const OuterPageTemplate: React.FC<OuterPageTemplateProps> = (props) => {
  const { children } = props;

  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  return (
    <div className="main-wrapper">
      <Header />
      <main className="main-wrapper__content">{children}</main>
      <Footer />
      <ContactsNav />
    </div>
  );
};

export default OuterPageTemplate;
