import { getNews, getPost } from "./api";
import { useQuery } from "react-query";
import { GetNewsRequest, GetPostRequest } from "../utils";

const News = "news";
const NewsContent = "news-content";

export const useNewsList = (params: GetNewsRequest) => {
  return useQuery([News, params], (k, params) => getNews(params));
};

export const useNewsContent = (params: GetPostRequest) => {
  return useQuery([NewsContent, params], (k, params) => getPost(params));
};
