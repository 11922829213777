import React from "react";
import { OuterPageTemplate, SectionTemplate } from "../../../modules/Layouts";
import { _t } from "../../../modules/Common/components/InjectIntlContext";
import { getLocale } from "../../../system/helpers/localstorageHelper";
import { useTournaments } from "./api";
import { Spin } from "antd";
import { TournamentPreview } from "./components";

const Tournaments = () => {
  const locale = getLocale();
  const { data, isLoading } = useTournaments({
    language: locale,
  });
  return (
    <OuterPageTemplate>
      <SectionTemplate sectionName="tournaments" className="top-page-indent">
        <h1 className="page-title page-title--center">{_t("tournaments")}</h1>
        <Spin spinning={isLoading}>
          {data?.tournaments?.map((item, idx) => (
            <TournamentPreview tournament={item} key={idx} />
          ))}
        </Spin>
      </SectionTemplate>
    </OuterPageTemplate>
  );
};

export default Tournaments;
