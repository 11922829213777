import React from "react";

interface TextContentProps {
  text: string;
}

const TextContent: React.FC<TextContentProps> = ({ text }) => {
  return (
    <div
      className="page-text-content"
      dangerouslySetInnerHTML={{ __html: text }}
    ></div>
  );
};

export default TextContent;
