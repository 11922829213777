import React from "react";
import ReCAPTCHA from "react-google-recaptcha";

import { OuterPageTemplate, SectionTemplate } from "../../../modules/Layouts";
import { _t } from "../../../modules/Common/components/InjectIntlContext";
import { useFeedbackSend } from "./api";
import { getLocale } from "../../../system/helpers/localstorageHelper";
import {Col, Form, Row} from "antd";
import { APP_ROUTES } from "../../../system/constants/constantsUrl";
import Input from "antd/lib/input";
import config from "../../../system/config";
import {IncomeButton} from "../components";

const Contacts = () => {
  const [SendContacts, { isLoading }] = useFeedbackSend();
  const recaptchaRef = React.createRef();
  const locale = getLocale();

  return (
    <OuterPageTemplate>
      <SectionTemplate sectionName="contacts" className="top-page-indent">
        <h1 className="page-title page-title--center contacts__title">{_t("get_in_touch")}</h1>
        <p className="contacts__sub-title">{_t("contacts_welcome_message")}</p>
        <Form
          action={APP_ROUTES.contacts}
          name="contacts_form"
          className="contact-us-form"
          method="post"
          onFinish={SendContacts}
        >
          <Row gutter={20}>
            <Col md={12} span={24}>
              <Form.Item
                name="name"
                rules={[
                  {
                    required: true,
                    message: _t("input_name"),
                  },
                ]}
              >
                <Input placeholder={_t("Name")}/>
              </Form.Item>
            </Col>
            <Col md={12} span={24}>
              <Form.Item
                name="email"
                rules={[
                  {
                    required: true,
                    message: _t("input_email"),
                  },
                  {
                    type: "email",
                    message: _t("not_valid_email_message"),
                  },
                ]}
              >
                <Input placeholder={_t("Email")} />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item
            name="text"
            className="textarea-wrap"
            rules={[
              {
                required: true,
                message: _t("Please input details"),
              },
            ]}
          >
            <Input.TextArea rows={4} placeholder={_t("your_message")} />
          </Form.Item>
          <Form.Item
            name="g-recaptcha-response"
            className='contact-us-form__recaptcha-wrap'
            rules={[
              {
                required: true,
                message: _t("recaptcha_required"),
              },
            ]}
          >
            <ReCAPTCHA
              ref={recaptchaRef}
              sitekey={config.google_recaptcha_sitekey}
              className={"contact-us-form__recaptcha"}
              hl={locale}
            />
          </Form.Item>
          <IncomeButton type='submit' text={_t("Send")} disabled={isLoading} className='contact-us-form__btn'/>
        </Form>
      </SectionTemplate>
    </OuterPageTemplate>
  );
};

export default Contacts;
