import React, { useState } from "react";
import { getLocale } from "../../../system/helpers/localstorageHelper";
import { useFaqsList } from "./api";
import { OuterPageTemplate, SectionTemplate } from "../../../modules/Layouts";
import { _t } from "../../../modules/Common/components/InjectIntlContext";
import {FAQList, FAQNav} from "./components";

const FAQ = () => {
  const [filter, setFilter] = useState("general");
  const locale = getLocale();
  const { data, isLoading } = useFaqsList({
    category: filter,
    language: locale,
  });

  return (
    <OuterPageTemplate>
      <SectionTemplate sectionName="faq" className='top-page-indent' containerSize='small'>
        <h1 className="page-title">{_t("FAQ")}</h1>
        <FAQNav data={data} setFilter={setFilter} isLoading={isLoading}/>
        <FAQList data={data} isLoading={isLoading}/>
      </SectionTemplate>
    </OuterPageTemplate>
  );
};

export default FAQ;
