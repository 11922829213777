import React from "react";
import { OuterPageTemplate, SectionTemplate } from "../../../modules/Layouts";
import { _t } from "../../../modules/Common/components/InjectIntlContext";
import {NewsList} from "./components";

const News = () => {
  return (
    <OuterPageTemplate>
      <SectionTemplate sectionName="news top-page-indent">
        <h1 className="page-title">{_t("news")}</h1>
        <NewsList/>
      </SectionTemplate>
    </OuterPageTemplate>
  );
};

export default News;
