import React from "react";
import { OuterPageTemplate, SectionTemplate } from "../../../modules/Layouts";
import { CommissionContent } from "./components";
import { _t } from "../../../modules/Common/components/InjectIntlContext";

const points = (_t: any) => [
  {
    text: _t("subaffiliate_1"),
  },
  {
    text: _t("subaffiliate_2"),
  },
  {
    text: _t("subaffiliate_3"),
  },
  {
    text: _t("subaffiliate_4"),
  },
];
const SubAffiliate = () => {
  const translatedPoints = points(_t);
  return (
    <OuterPageTemplate>
      <SectionTemplate
        sectionName="commission-section"
        className="top-page-indent"
      >
        <CommissionContent title={_t("subaffiliate_title")} points={translatedPoints} />
      </SectionTemplate>
    </OuterPageTemplate>
  );
};

export default SubAffiliate;
