import React from "react";
import { NavLink } from "react-router-dom";
import { APP_ROUTES } from "../../../../system/constants/constantsUrl";
import { _t } from "../../../../modules/Common/components/InjectIntlContext";

const links = (_t: any) => [
  {
    title: _t("testimonials"),
    path: APP_ROUTES.testimonials,
    mod: "testimonials",
  },
  {
    title: _t("FAQ"),
    path: APP_ROUTES.faq,
    mod: "faq",
  },
  {
    title: _t("Contacts"),
    path: APP_ROUTES.contacts,
    mod: "contacts",
  },
];
interface HeaderNavProps {
  isLogged: boolean;
}
const HeaderNav: React.FC<HeaderNavProps> = ({ isLogged }) => {
  const linkTranslated = links(_t);
  return (
    <nav className="header-nav">
      <ul className="header-nav__list">
        {linkTranslated.map((item, idx) => (
          <li className="header-nav__item" key={idx}>
            <NavLink
              to={item.path}
              className={`header-nav__link header-nav__link--${item.mod}`}
            >
              {item.title}
            </NavLink>
          </li>
        ))}

        {isLogged && (
          <li className="header-nav__item">
            <NavLink
              to={APP_ROUTES.dashboard.general}
              className={`header-nav__link header-nav__link--dashboard`}
            >
              {_t("Dashboard")}
            </NavLink>
          </li>
        )}
      </ul>
    </nav>
  );
};

export default HeaderNav;
