import React from "react";
import { APP_ROUTES } from "../../../../system/constants/constantsUrl";
import { _t } from "../../../../modules/Common/components/InjectIntlContext";
import { NavLink } from "react-router-dom";

const links = (_t: any) => [
  {
    title: _t("presentation"),
    path: APP_ROUTES.presentation,
  },
  {
    title: _t("conditions"),
    path: APP_ROUTES.terms,
  },
  // {
  //     title: _t("Brands"),
  //     path: APP_ROUTES.brands.index,
  // },
  {
    title: _t("Commissions"),
    path: APP_ROUTES.commissions.commission,
  },
  // {
  //   title: _t("news"),
  //   path: APP_ROUTES.news.list,
  // },
  {
    title: _t("tournaments"),
    path: APP_ROUTES.tournaments.list,
  },
  {
    title: _t("FAQ"),
    path: APP_ROUTES.faq,
  },
  {
    title: _t("postback_options"),
    path: APP_ROUTES.postback_options,
  },
  {
    title: _t("testimonials"),
    path: APP_ROUTES.testimonials,
  },
  {
    title: _t("Contacts"),
    path: APP_ROUTES.contacts,
  },
];
const FooterNav = () => {
  const linksTranslated = links(_t);
  return (
    <ul className="footer-nav">
      {linksTranslated.map((item, idx) => (
        <li key={idx} className="footer-nav__item">
          <NavLink to={item.path} exact className="footer-nav__link">
            {item.title}
          </NavLink>
        </li>
      ))}
    </ul>
  );
};

export default FooterNav;
