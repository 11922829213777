import React from "react";
import Slider, { Settings } from "react-slick";
import "slick-carousel/slick/slick.css";
import { MainBannerItem } from "../MainBannerItem";
import { _t } from "../../../../modules/Common/components/InjectIntlContext";

const slides = (_t: any) => [
  {
    title: _t("slide_1_title"),
    title_2: _t("slide_1_title_2"),
    text: _t("slide_1_text"),
  },
  {
    title: _t("slide_2_title"),
    text: _t("slide_2_text"),
  },
  {
    title: _t("slide_3_title"),
    text: _t("slide_3_text"),
  },
];

const MainSlider = () => {
  const translatedSlides = slides(_t);
  const settings: Settings = {
    speed: 500,
    fade: true,
    arrows: false,
    dots: true,
  };
  return (
    <Slider {...settings} className="main-banner-slider">
      {translatedSlides.map((item, idx) => (
        <MainBannerItem info={item} key={idx}/>
      ))}
    </Slider>
  );
};

export default MainSlider;
