import React, { useEffect, useState } from "react";
import { Spin } from "antd";
import { AffiliateLandingPageCard } from "./index";
import { useGetLandingPagesList } from "../../hooks";
import { getLocale } from "../../../../../system/helpers/localstorageHelper";
import {
  GridContextProvider,
  GridDropZone,
  GridItem,
  swap,
} from "react-grid-dnd";
import {
  AffiliateLandingPagesListItem,
  getColPerRow,
  gridLayoutInterface,
} from "../../utils";
import { usePostLandingsOrder } from "../../hooks/usePostLandingsOrder";

interface AffiliateLandingPagesListProps {
  status: string;
  id: number;
}
const AffiliateLandingPagesList: React.FC<AffiliateLandingPagesListProps> = ({
  status,
  id,
}) => {
  const locale = getLocale();
  const { data, isLoading } = useGetLandingPagesList({
    project_id: id,
    status: status,
    language: locale,
  });
  const [sendLandingsOrder] = usePostLandingsOrder();

  const gridId = `landing_pages_${id}`;
  const [grid, setGrid] = React.useState<AffiliateLandingPagesListItem>();
  const [gridLayout, setGridLayout] = useState<gridLayoutInterface>({
    total: data?.landing_pages.length,
    col: getColPerRow(),
    colHeight: 370,
  });
  useEffect(() => {
    if (data) {
      setGrid({ [gridId]: data?.landing_pages });
      setGridHeight(
        calcDndLayout(
          data?.landing_pages.length,
          getColPerRow(),
          gridLayout.colHeight
        )
      );
    }
  }, [data]);

  useEffect(() => {
    const handleGridUpdate = () => {
      setGridLayout({
        ...gridLayout,
        col: getColPerRow(),
      });
      setGridHeight(
        calcDndLayout(
          data?.landing_pages.length,
          getColPerRow(),
          gridLayout.colHeight
        )
      );
    };
    window.addEventListener("resize", handleGridUpdate);
    return () => {
      window.removeEventListener("resize", handleGridUpdate);
    };
  }, [window.innerWidth]);

  const calcDndLayout = (total: number, cols: number, colHeight: number) => {
    return Math.ceil(total / cols) * colHeight;
  };

  const [gridHeight, setGridHeight] = useState(
    calcDndLayout(gridLayout.total, gridLayout.col, gridLayout.colHeight)
  );
  const onDragEnd = (sourceId, sourceIndex, targetIndex) => {
    if (sourceIndex === targetIndex) return;

    const result = swap(grid[sourceId], sourceIndex, targetIndex).map(
      (item, idx) => ({
        ...item,
        sort_order: idx,
      })
    );

    const sort = result
      .filter((item, idx) => grid[sourceId][idx].id !== item.id)
      .map((item, idx) => {
        return {
          id: item.id,
          sort_order: idx,
        };
      });

    sendLandingsOrder({
      sort,
    });

    return setGrid({
      ...grid,
      [sourceId]: result,
    });
  };

  return (
    <Spin spinning={isLoading} style={{ display: "block" }}>
      <GridContextProvider onChange={onDragEnd}>
        <GridDropZone
          id={gridId}
          boxesPerRow={gridLayout.col}
          rowHeight={gridLayout.colHeight}
          style={{
            height: `${gridHeight}px`,
          }}
          className="landings-dnd"
        >
          {grid &&
            grid[gridId]
              .sort((a, b) => a.sort_order - b.sort_order)
              .map((item) => {
                return (
                  <GridItem key={item.id} className="landings-dnd__item">
                    <AffiliateLandingPageCard landingPreviewInfo={item} />
                  </GridItem>
                );
              })}
        </GridDropZone>
      </GridContextProvider>
    </Spin>
  );
};

export default AffiliateLandingPagesList;
