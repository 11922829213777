import React from 'react';
import {OuterPageTemplate, SectionTemplate} from "../../../modules/Layouts";
import {_t} from "../../../modules/Common/components/InjectIntlContext";
import {useTranslation} from "../../../modules/Landing/apiHooks";
import {getLocale} from "../../../system/helpers/localstorageHelper";
import {Spin} from "antd";
import {TextContent} from "../components";

const Terms = () => {
  const { data, isLoading } = useTranslation({
    language: getLocale(),
    name: "conditions",
  });
  return (
    <OuterPageTemplate>
      <SectionTemplate sectionName='terms' className='top-page-indent'>
        <h1 className='page-title page-title--center'>{_t("conditions")}</h1>
        <Spin spinning={isLoading}>
          <TextContent text={data?.text}/>
        </Spin>
      </SectionTemplate>
    </OuterPageTemplate>
  );
};

export default Terms;