import React from "react";
import { AuthTemplate } from "../../../modules/Layouts";
import { _t } from "../../../modules/Common/components/InjectIntlContext";
import { useRestorePassword } from "../../../modules/Auth/apiHooks";
import { PasswordRestoreForm } from "./components";
import { getAdminToken } from "../../../system/helpers/localstorageHelper";

const PasswordRestore = () => {
  const [submit, { isSuccess }] = useRestorePassword();
  const accessCheck = !!getAdminToken();
  return (
    <AuthTemplate accessCheck>
      <div className="auth-box">
        <h2 className="auth-box__title">{_t("Reset password")}</h2>
        {isSuccess ? (
          <p className="auth-box__sub-title">
            {_t("Please visit your email and follow the instruction")}
          </p>
        ) : (
          <PasswordRestoreForm submit={submit} accessCheck />
        )}
      </div>
    </AuthTemplate>
  );
};

export default PasswordRestore;
