import React from "react";
import { _t } from "../../../../modules/Common/components/InjectIntlContext";
interface TopTestimonialItemProps {
  info: {
    title: string;
    content: string;
    url: string;
  };
}
const TopTestimonialItem: React.FC<TopTestimonialItemProps> = ({ info }) => {
  const { title, content, url } = info;
  return (
    <div className="top-testimonial-item">
      <div className="top-testimonial-item__card">
        <h4 className="top-testimonial-item__title">{_t("simply_perfect")}</h4>
        <div
          dangerouslySetInnerHTML={{ __html: content }}
          className="top-testimonial-item__info"
        ></div>
      </div>
      <h4 className="top-testimonial-item__brand">{title}</h4>
      <a
        href={url}
        target="_blank"
        rel="noreferrer nofollow"
        className="top-testimonial-item__link"
      >
        {title}
      </a>
    </div>
  );
};

export default TopTestimonialItem;
