import { _t } from "../../../../../modules/Common/components/InjectIntlContext";
import { useMutation } from "react-query";
import { notify, notifyMap } from "../../../../../common/helpers";
import { contactSend } from "../api";

export const useContactsSend = () => {
  const messageSentTranslated = _t("message_sent");
  return useMutation(contactSend, {
    onSuccess: (data, variables) => {
      if (data.status === "ok") {
        notify("success", messageSentTranslated);
      } else {
        notifyMap("error", data.message);
      }
    },
    onError: (error) => {
      notify("error", "error");
    },
  });
};
