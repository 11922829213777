import {
  AffiliateGeoDetail,
  CountryItem,
  GamerProfileLink,
  PartnerProfileLink,
  Percent,
} from "../../../components";
import {
  currencyFields,
  renderCurrency,
} from "../../../../../../modules/Dashboard/constants";
import { CurrencyLabel } from "../../../../../../modules/Common/components";
import { getCurrencySymbol } from "../../../../../../common/helpers";

// todo "add conditions for specific report"
export const renderCellValue = (key, text, record) => {
  const isCurrency = currencyFields.includes(key);
  const isConvertedCurrency = key.includes("_converted");
  const symbol = record.currency_to_symbol;

  const values = { text, record };
  const isCurrentKey = (value) => key === value;
  const isKeyContain = (value) => key.includes(value);

  if (isCurrentKey("gamer_id")) return GamerProfileLink(values);
  if (isCurrentKey("partner_id")) return PartnerProfileLink(values);
  if (isCurrentKey("id")) return PartnerProfileLink(values);
  if (isKeyContain("_rel_")) return Percent(text);
  if (isCurrentKey("affiliate_geo_details"))
    return AffiliateGeoDetail(record.partner_id);
  if (isCurrentKey("country_full"))
    return CountryItem({
      code: record.country,
      name: record.country_full,
    });
  if (isCurrentKey("rd_percent"))
    return Number(text / 100).toLocaleString(undefined, {
      style: "percent",
      minimumFractionDigits: 2,
    });
  if (isCurrency && isConvertedCurrency)
    return CurrencyLabel({ value: text, symbol });
  if (isCurrency)
    return CurrencyLabel({
      symbol: getCurrencySymbol(record.date),
      value: text,
    });
  return text;
};
