import React from "react";
import { PresentationVideo } from "../PresentationVideo";
import { _t } from "../../../../../modules/Common/components/InjectIntlContext";

const tabs = (t) => [
  {
    video: t("presentation_video_1"),
  },
  {
    video: t("presentation_video_2"),
  },
  {
    video: t("presentation_video_3"),
  },
];
const PresentationContainer = ({ activeTab }) => {
  const tabsContent = tabs(_t);
  const tabItem = (item, idx) => (
    <PresentationVideo
      isActive={activeTab === idx}
      src={item.video}
      key={idx}
    />
  );
  return (
    <div className="presentation-container">{tabsContent.map(tabItem)}</div>
  );
};

export default PresentationContainer;
