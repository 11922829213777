const IMAGES =
  "https://income-front-data.s3.fr-par.scw.cloud/income/images/partners";
export const PARTNERS_LIST = (t) => [
  {
    logo: `${IMAGES}/bt.svg`,
    title: "Bettilt",
    text: t("partners_list_text_bettilt"),
    link_path: t("partners_list_link_bettilt"),
  },
  {
    logo: `${IMAGES}/btt.svg`,
    title: "Bettilt Turkish",
    text: t("partners_list_text_bettilt_tr"),
    link_path: t("partners_list_link_bettilt_tr"),
  },
  {
    logo: `${IMAGES}/bh.svg`,
    title: "BahseGel",
    text: t("partners_list_text_bahsegel"),
    link_path: t("partners_list_link_bahsegel"),
  },
  {
    logo: `${IMAGES}/vemapostar.svg`,
    title: "VemApostar",
    text: t("partners_list_text_vemapostar"),
    link_path: t("partners_list_link_vemapostar"),
  },
  {
    logo: `${IMAGES}/roku.svg`,
    title: "Rokubet",
    text: t("partners_list_text_rokubet"),
    link_path: t("partners_list_link_rokubet"),
  },
  {
    logo: `${IMAGES}/pb.svg`,
    title: "PariBahis",
    text: t("partners_list_text_paribahis"),
    link_path: t("partners_list_link_paribahis"),
  },
  {
    logo: `${IMAGES}/garage.svg`,
    title: "Garage Stream",
    text: t("partners_list_text_garage"),
    link_path: t("partners_list_link_garage"),
  },
  // {
  //   logo: `${IMAGES}/asper.svg`,
  //   title: "Asper Casino",
  //   text: t("partners_list_text_asper"),
  //   link_path: t("partners_list_link_asper"),
  // },
  // {
  //   logo: `${IMAGES}/trbet.svg`,
  //   title: "TrBet",
  //   text: t("partners_list_text_trbet"),
  //   link_path: t("partners_list_link_trbet"),
  // },
  // {
  //   logo: `${IMAGES}/showbet.svg`,
  //   title: "Show Bet",
  //   text: t("partners_list_text_showbet"),
  //   link_path: t("partners_list_link_showbet"),
  // },
  // {
  //   logo: `${IMAGES}/cslot.svg`,
  //   title: "CSlot",
  //   text: t("partners_list_text_cslot"),
  //   link_path: t("partners_list_link_cslot"),
  // },
  // {
  //   logo: `${IMAGES}/super-partners.svg`,
  //   title: "Super Partners",
  //   text: t("partners_list_text_super_partners"),
  //   link_path: t("partners_list_link_super_partners"),
  //   isDisabled: true,
  // },
  // {
  //   logo: `${IMAGES}/superboss.svg`,
  //   title: "Super Boss Partners",
  //   text: t("partners_list_text_super_boss"),
  //   link_path: t("partners_list_link_super_boss"),
  // },
  // {
  //   logo: `${IMAGES}/betzmark.svg`,
  //   title: "Betzmark Partners",
  //   text: t("partners_list_text_betzmark"),
  //   link_path: t("partners_list_link_betzmark"),
  // },
];
