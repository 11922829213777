import React, { useEffect, useState } from "react";
import { PromoProgramsResponce, PromoProgramsData } from "../../constants";
import PromoCard from "../../../Common/components/PromoCard";
import { Row, Col, Spin } from "antd";
import ProgramImg from "../../../../images/promo/program.svg";
import ProjectInfo from "./ProjectInfo";
import { _t } from "../../../Common/components/InjectIntlContext";
import { useGetDataWithoutParams } from "../../../../common/hooks";
import { getItem } from "../../../../system/helpers/localstorageHelper";

type FirstStepProps = {
  data: PromoProgramsResponce;
  isLoading: boolean;
  onItemSelect: any;
  selectedProject: PromoProgramsData | null;
  nextStep: any;
};

const FirstStep: React.FC<FirstStepProps> = ({
  onItemSelect,
  data,
  isLoading,
  selectedProject,
  nextStep,
}) => {
  const {
    data: userProjects,
    isLoading: isUserProjectsLoading,
  } = useGetDataWithoutParams(
    ["API_KEY_GET_USER_PROJECTS", getItem("token")],
    "/api/users/projects/list"
  );
  const click = (item) => {
    onItemSelect(item);
  };
  let promo_id = new URL(window.location.href).searchParams.get("promo_id");
  const isRemoveBrands = (brandID: number) => {
    return (
      userProjects?.projects.filter((item) => item.id === brandID).length > 0
    );
  };
  const isDemo = window.location.hostname === "demo.income.partners";

  return (
    <Spin spinning={isLoading || isUserProjectsLoading}>
      {isRemoveBrands(4) && !isDemo && (
        <div className="moved-brand">
          <a
            href="https://bahsegel.partners/"
            target="_blank"
            rel="noreferrer"
            className="moved-brand__logo"
          >
            <img
              src="https://income-front-data.s3.fr-par.scw.cloud/income/images/brands/BGP.svg"
              alt=""
            />
          </a>
          <div className="moved-brand__info">
            <p>
              Dear Users!
              <br /> BahseGel project is available by the following link{" "}
              <a
                href="https://bahsegel.partners/"
                target="_blank"
                rel="noreferrer"
              >
                bahsegel.partners
              </a>
              <br />
              To sign in please use your existing credentials.
            </p>
          </div>
        </div>
      )}
      {isRemoveBrands(17) && !isDemo && (
        <div className="moved-brand">
          <a
            href="https://paribahis.partners/"
            target="_blank"
            rel="noreferrer"
            className="moved-brand__logo"
          >
            <img
              src="https://income-front-data.s3.fr-par.scw.cloud/income/images/brands/paribahis.svg"
              alt=""
            />
          </a>
          <div className="moved-brand__info">
            <p>
              Dear Users!
              <br /> PariBahis project is available by the following link{" "}
              <a
                href="https://paribahis.partners/"
                target="_blank"
                rel="noreferrer"
              >
                paribahis.partners
              </a>
              <br />
              To sign in please use your existing credentials.
            </p>
          </div>
        </div>
      )}
      <Row gutter={[20, 20]}>
        {data?.data.map((item) => {
          if (promo_id === item.id) {
            click(item);
            nextStep();
          }
          return (
            <Col key={item.id} xs={24} sm={12} onClick={() => click(item)}>
              <PromoCard
                img={ProgramImg}
                checked={!!selectedProject && selectedProject.id === item.id}
                info={
                  <ProjectInfo
                    img={item.project.image}
                    type={item.name}
                    href={item.project.url}
                  />
                }
              />
            </Col>
          );
        })}
      </Row>
    </Spin>
  );
};

export default FirstStep;
