import prodConfig from "./prod";
import stageConfig from "./stage";
const CONFIG_ENV = process.env.REACT_APP_CONFIG_ENV || "";
export const ADDITIONAL_CURRENCY_SYMBOL_DATE = "2022-11-15";
export const ADDITIONAL_CURRENCY_SYMBOL = "₺";

const config = {
  google_recaptcha_sitekey: "6LextLoUAAAAADqqnjhxQMrcuNOZhMZNkEa6d9Sg",
  projectCurrency: "€",
};

const reactConfigEnv = CONFIG_ENV === "stage" ? stageConfig : prodConfig;
export default { ...config, ...reactConfigEnv };
