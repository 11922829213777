import React from "react";
import { AuthTemplate } from "../../../modules/Layouts";
import { useRegister } from "./api";
import { REGISTRATION_SUCCESS_MESSAGE } from "../../../modules/Auth/constants";
import { SignUpForm, SuccessRegistration } from "./components";

const SignUp = () => {
  const [register, { isLoading, data }] = useRegister();

  return (
    <AuthTemplate>
      <div className="auth-box">
        {data?.status === REGISTRATION_SUCCESS_MESSAGE ? (
          <SuccessRegistration />
        ) : (
          <SignUpForm name="register" submit={register} isLoading={isLoading} />
        )}
      </div>
    </AuthTemplate>
  );
};

export default SignUp;
