import React from "react";
import { OuterPageTemplate, SectionTemplate } from "../../../modules/Layouts";
import { _t } from "../../../modules/Common/components/InjectIntlContext";
import { CommissionsList } from "../components";

const Commissions = () => {
  return (
    <OuterPageTemplate>
      <SectionTemplate sectionName="commissions" className="top-page-indent">
        <h1 className="page-title page-title--center">{_t("Commission")}</h1>
        <CommissionsList />
      </SectionTemplate>
    </OuterPageTemplate>
  );
};

export default Commissions;
