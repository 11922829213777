import React from "react";
import { ContactMailIcon, ContactSkypeIcon, ContactTgIcon } from "../Icons";

const ContactsNav = () => {
  const navItems = [
    {
      path: "https://t.me/incomepartners",
      icon: <ContactTgIcon />,
    },
    {
      path: "skype:live:.cid.876280c7ef1edbaf?chat",
      icon: <ContactSkypeIcon />,
    },
    {
      path: "mailto:support@income.partners",
      icon: <ContactMailIcon />,
    },
  ];

  return (
    <ul className="contacts-nav">
      {navItems.map((item, idx) => (
        <li className="contacts-nav__item" key={idx}>
          <a
            href={item.path}
            target="_blank"
            rel="noopener noreferrer"
            className="contacts-nav__link"
          >
            {item.icon}
          </a>
        </li>
      ))}
    </ul>
  );
};

export default ContactsNav;
