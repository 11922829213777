import React from "react";
import { Col, Row, Spin } from "antd";
import { FAQItem } from "./index";

const FAQList = ({ data, isLoading }) => {
  return (
    <Spin spinning={isLoading}>
      <Row className="faq-list" gutter={50}>
        {data?.faq.map((item) => (
          <Col span={24} lg={12} key={item.id}>
            <FAQItem info={item} />
          </Col>
        ))}
      </Row>
    </Spin>
  );
};
export default FAQList;
