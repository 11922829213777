import React from "react";
import {NavLink, useParams} from "react-router-dom";
import {APP_ROUTES} from "../../../system/constants/constantsUrl";
import { getLocale } from "../../../system/helpers/localstorageHelper";
import { useNewsContent } from "../../../modules/News/apiHooks";
import { OuterPageTemplate, SectionTemplate } from "../../../modules/Layouts";
import { Spin } from "antd";
import {_t} from "../../../modules/Common/components/InjectIntlContext";

const Article = () => {
  const { slug } = useParams();
  const locale = getLocale();
  const { data, isLoading } = useNewsContent({
    language: locale,
    slug: slug,
  });

  return (
    <OuterPageTemplate>
      <SectionTemplate sectionName="article-section" className='top-page-indent'>
        <Spin spinning={isLoading}>
          {data && (
            <div className="article">
              <div className="article__image">
                <img
                  src={`${data.post.host}${data.post.options_parsed.image}`}
                  alt={data.post.caption}
                />
              </div>
              <h2 className="article__title">{data.post.caption}</h2>
              <p
                className="article__text"
                dangerouslySetInnerHTML={{ __html: data.post.text }}
              ></p>
              <NavLink to={APP_ROUTES.news.list} className='income-button article__btn'>
                {_t('back_to_news')}
              </NavLink>
            </div>
          )}
        </Spin>
      </SectionTemplate>
    </OuterPageTemplate>
  );
};

export default Article;
