import React from "react";
import { Form, Input } from "antd";
import { _t } from "../../../../../modules/Common/components/InjectIntlContext";
import { AuthMailIcon, AuthPasswordIcon } from "../../../components/Icons";
import { APP_ROUTES } from "../../../../../system/constants/constantsUrl";
import { IncomeButton } from "../../../components";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";

interface SignInFormProps {
  name?: string;
  submit?: any;
  accessCheck?: boolean;
}
const SignInForm: React.FC<SignInFormProps> = ({
  name,
  submit,
  accessCheck,
}) => {
  let history = useHistory();

  return (
    <Form name={name} onFinish={submit} className="auth-form">
      <h2 className="auth-form__title">{_t("welcome")}</h2>
      <Form.Item
        name="login"
        rules={[
          {
            required: true,
            message: _t("enter_login_message"),
            whitespace: true,
          },
        ]}
        className="auth-form__item"
      >
        <div className="auth-input-box">
          <Input className="auth-input" placeholder={_t("Email")} />
          <div className="auth-input-box__icon">
            <AuthMailIcon />
          </div>
        </div>
      </Form.Item>
      <Form.Item
        name="password"
        rules={[
          {
            required: true,
            message: _t("input_password_message"),
            whitespace: true,
          },
        ]}
        className="auth-form__item"
      >
        <div className="auth-input-box">
          <Input.Password className="auth-input" placeholder={_t("Password")} />
          <div className="auth-input-box__icon">
            <AuthPasswordIcon />
          </div>
        </div>
      </Form.Item>
      <Link
        to={APP_ROUTES.user.restore_password}
        className="auth-form__link auth-form__link--right-align"
      >
        {_t("forgot_password")}
      </Link>
      <div className="auth-form__buttons">
        <IncomeButton
          type="submit"
          text={_t("Login")}
          className="income-button--small auth-form__btn"
        />
        {accessCheck ? (
          <IncomeButton
            handler={() => history.goBack()}
            className="income-button income-button--small-border income-button--small auth-form__btn"
            style={{ marginTop: "20px" }}
            text={_t("Back")}
          />
        ) : (
          <>
            <p className="auth-form__divider-txt">{_t("no_acc_msg")}</p>
            <Link
              to={APP_ROUTES.registration.main}
              className="income-button income-button--small-border income-button--small auth-form__btn"
            >
              {_t("regs")}
            </Link>
          </>
        )}
      </div>
    </Form>
  );
};

export default SignInForm;
