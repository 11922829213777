import React from "react";
import { NavLink } from "react-router-dom";
import {_t} from "../../Common/components/InjectIntlContext";
import {APP_ROUTES} from "../../../system/constants/constantsUrl";

const links = (_t: any) => [
    {
        title: _t("conditions"),
        path: APP_ROUTES.terms,
    },
    // {
    //     title: _t("AffiliateBrands"),
    //     path: APP_ROUTES.brands.index,
    // },
    {
        title: _t("Commissions"),
        path: APP_ROUTES.commissions.commission,
    },
    {
        title: _t("news"),
        path: APP_ROUTES.news.list,
    },
    {
        title: _t("tournaments"),
        path: APP_ROUTES.tournaments.list,
    },
    {
        title: _t("FAQ"),
        path: APP_ROUTES.faq,
    },
    {
        title: _t("postback_options"),
        path: APP_ROUTES.postback_options,
    },
    {
        title: _t("testimonials"),
        path: APP_ROUTES.testimonials,
    },
    {
        title: _t("Contacts"),
        path: APP_ROUTES.contacts,
    },
];

const Footer = () => {
    const translatedLinks = links(_t);

    return (
        <section className="footer">
            <div className="footer__wrapper">
                <span className="footer__info">© 2018. Income.partners</span>
                <ul className="footer__menu">
                    {translatedLinks.map((link) => (
                      <li key={link.title} className="footer__menu-item"><NavLink to={link.path}>{link.title}</NavLink></li>
                    ))}
                </ul>
            </div>
        </section>
    );
};

export default Footer;
