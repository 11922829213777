import React, { useEffect } from "react";
import Header from "../Landing/components/Header";
import Footer from "../Landing/components/Footer";
import SocialNetworks from "../Landing/components/SocialNetworks";

const LandingTemplate = (props) => {
  const { children, wrapClass } = props;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [window.location.pathname]);

  return (
    <div className="landing-pages">
      <div className={wrapClass ?? "main-wrap"}>
        <Header />
        {children}
        <SocialNetworks />
        <Footer />
      </div>
    </div>
  );
};

export default LandingTemplate;