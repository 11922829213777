import React from 'react';
import {OuterPageTemplate, SectionTemplate} from "../../../modules/Layouts";
import {CommissionContent} from "./components";
import {_t} from "../../../modules/Common/components/InjectIntlContext";
const points = (_t: any) => [
  {
    text: _t("revshare_1"),
  },
  {
    text: _t("revshare_2"),
  },
  {
    text: _t("revshare_3"),
  },
  {
    text: _t("revshare_4"),
  },

];
const RevShare = () => {
  const translatedPoints = points(_t);
  return (
    <OuterPageTemplate>
      <SectionTemplate
        sectionName="commission-section"
        className="top-page-indent"
      >
        <CommissionContent title={_t("revshare_title")} points={translatedPoints} />
      </SectionTemplate>
    </OuterPageTemplate>
  );
};

export default RevShare;