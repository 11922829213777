import React from "react";
import { AuthTemplate } from "../../../modules/Layouts";
import { _t } from "../../../modules/Common/components/InjectIntlContext";
import { useSignin } from "./utils";
import { SignInForm } from "./components";
import {
  returnAdminHelper,
  setItem,
} from "../../../system/helpers/localstorageHelper";

interface SignInProps {
  accessCheck?: boolean;
}
const SignIn: React.FC<SignInProps> = ({ accessCheck }) => {
  const [useSigninHandler] = useSignin(_t);
  const SubmitSignIn = (values) => {
    setItem("isShowNotifications", true);
    if (accessCheck) {
      returnAdminHelper();
    }
    useSigninHandler(values);
  };

  return (
    <>
      <AuthTemplate accessCheck={accessCheck}>
        <div className="auth-box">
          <SignInForm
            name="auth_form"
            submit={SubmitSignIn}
            accessCheck={accessCheck}
          />
        </div>
      </AuthTemplate>
    </>
  );
};

export default SignIn;
