import React from "react";
import { getLocale } from "../../../../system/helpers/localstorageHelper";
import { useNewsList } from "../api";
import {Col, Row, Spin} from "antd";
import {NewsItem} from "./index";

const NewsList = () => {
  const locale = getLocale();
  const { data, isLoading } = useNewsList({
    language: locale,
  });
  return (
    <Spin spinning={isLoading}>
      <Row gutter={12} className='news-list'>
        {
          data?.news?.map((post) =>(
            <Col key={post.id} span={24} lg={12}>
              <NewsItem post={post}/>
            </Col>
          ))
        }
      </Row>
    </Spin>
  );
};

export default NewsList;
