import React from "react";
import { Checkbox, Form, Input } from "antd";
import ReCAPTCHA from "react-google-recaptcha";
import {
  getItem,
  getLocale,
} from "../../../../../system/helpers/localstorageHelper";
import { INVITATION_CODE } from "../../../../../modules/Landing/constants";
import { _t } from "../../../../../modules/Common/components/InjectIntlContext";
import {
  AuthContactIcon,
  AuthMailIcon,
  AuthPasswordIcon,
  AuthUserIcon,
} from "../../../components/Icons";
import { APP_ROUTES } from "../../../../../system/constants/constantsUrl";
import { IncomeButton } from "../../../components";
import { Link } from "react-router-dom";
import config from "../../../../../system/config";

const SignUpForm = ({ name, submit, isLoading }) => {
  const [form] = Form.useForm();
  const locale = getLocale();
  const recaptchaRef = React.createRef();
  const invitationCode = getItem(INVITATION_CODE);
  const formErrorMsg = {
    passMatch: _t("error_confirm_password_message"),
    terms: _t("accept_terms"),
  };
  return (
    <Form
      form={form}
      name={name}
      onFinish={submit}
      className="auth-form"
      initialValues={{
        invitation_code: invitationCode,
      }}
      scrollToFirstError
    >
      <h2 className="auth-form__title">{_t("welcome")}</h2>
      {invitationCode && (
        <Form.Item
          name="invitation_code"
          hidden={true}
          className="auth-form__item"
        >
          <div className="auth-input-box">
            <Input />
            <div className="auth-input-box__icon">
              <AuthMailIcon />
            </div>
          </div>
        </Form.Item>
      )}

      <Form.Item
        name="login"
        rules={[
          {
            required: true,
            message: _t("enter_login_message"),
            whitespace: true,
          },
        ]}
        className="auth-form__item"
      >
        <div className="auth-input-box">
          <Input placeholder={_t("Login")} />
          <div className="auth-input-box__icon auth-input-box__icon--user">
            <AuthUserIcon />
          </div>
        </div>
      </Form.Item>
      <Form.Item
        name="email"
        rules={[
          {
            type: "email",
            message: _t("not_valid_email_message"),
          },
          {
            required: true,
            message: _t("input_email_message"),
          },
        ]}
        className="auth-form__item"
      >
        <div className="auth-input-box">
          <Input placeholder={_t("Email")} />
          <div className="auth-input-box__icon">
            <AuthMailIcon />
          </div>
        </div>
      </Form.Item>
      <Form.Item
        name="phone"
        rules={[{ required: true, message: _t("valid_contacts_msg") }]}
        className="auth-form__item"
      >
        <div className="auth-input-box">
          <Input placeholder={_t("Contacts")} />
          <div className="auth-input-box__icon auth-input-box__icon--contact">
            <AuthContactIcon />
          </div>
        </div>
      </Form.Item>
      <Form.Item
        name="password"
        rules={[
          {
            required: true,
            message: _t("input_password_message"),
            whitespace: true,
          },
          {
            min: 6,
            message: _t("min_password_message"),
          },
        ]}
        className="auth-form__item"
      >
        <div className="auth-input-box">
          <Input.Password className="auth-input" placeholder={_t("Password")} />
          <div className="auth-input-box__icon">
            <AuthPasswordIcon />
          </div>
        </div>
      </Form.Item>
      <Form.Item
        name="password_confirmation"
        rules={[
          {
            required: true,
            message: _t("input_password_message"),
            whitespace: true,
          },

          ({ getFieldValue }) => ({
            validator(_, value) {
              if (!value || getFieldValue("password") === value) {
                return Promise.resolve();
              }
              return Promise.reject(new Error(formErrorMsg.passMatch));
            },
          }),
        ]}
        className="auth-form__item"
        dependencies={["password"]}
      >
        <div className="auth-input-box">
          <Input.Password
            className="auth-input"
            placeholder={_t("confirm_password")}
          />
          <div className="auth-input-box__icon">
            <AuthPasswordIcon />
          </div>
        </div>
      </Form.Item>
      <Form.Item
        name="terms"
        valuePropName="checked"
        rules={[
          {
            validator: (_, value) =>
              value ? Promise.resolve() : Promise.reject(formErrorMsg.terms),
          },
        ]}
        className="auth-form__item auth-form__item--check"
      >
        <div className="auth-input-box">
          <Checkbox className="income-checkbox">
            {_t("agree_with")}{" "}
            <a
              href={APP_ROUTES.terms}
              className="auth-form__link auth-form__link--decor"
            >
              {" "}
              {_t("terms_conditions")}
            </a>
          </Checkbox>
        </div>
      </Form.Item>
      <Form.Item
        name="g-recaptcha-response"
        rules={[
          {
            required: true,
            message: _t("recaptcha_required"),
          },
        ]}
        className="auth-form__item auth-form__item--recaptcha"
      >
        <ReCAPTCHA
          ref={recaptchaRef}
          sitekey={config.google_recaptcha_sitekey}
          hl={locale}
        />
      </Form.Item>
      <div className="auth-form__buttons">
        <IncomeButton
          type="submit"
          text={_t("regs")}
          className="income-button--small auth-form__btn"
          disabled={isLoading}
        />
        <p className="auth-form__divider-txt">{_t("have_acc_msg")}</p>
        <Link
          to={APP_ROUTES.login.main}
          className="income-button income-button--small-border income-button--small auth-form__btn"
        >
          {_t("Login")}
        </Link>
      </div>
    </Form>
  );
};

export default SignUpForm;
