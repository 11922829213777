import React, { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import { AuthTabs } from "../../pages/outer/components";
import { APP_ROUTES } from "../../system/constants/constantsUrl";
import { useRedirectionRoute } from "../../common/helpers";
import ReturnAdmin from "./special/ReturnAdmin";

interface AuthTemplateProps {
  children?: React.ReactNode;
  accessCheck?: boolean;
}

const AuthTemplate: React.FC<AuthTemplateProps> = ({
  children,
  accessCheck,
}) => {
  const { pathname } = useLocation();
  const userAuth = [
    APP_ROUTES.login.main,
    APP_ROUTES.registration.main,
  ].includes(pathname);
  const mdResolution = useMediaQuery({ query: "(max-width: 1023px)" });
  const [isLogged] = useRedirectionRoute("isLogged");
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      {!accessCheck && isLogged}
      {accessCheck && <ReturnAdmin />}
      <div className="auth-wrap">
        <div className="auth-wrap__box">
          {mdResolution && userAuth && <AuthTabs />}
          <div className="auth-wrap__content">{children}</div>
          {!mdResolution && <div className="auth-wrap__bg" />}
        </div>
        {!accessCheck && <Link to="/" className="auth-wrap__close" />}
      </div>
    </>
  );
};

export default AuthTemplate;
