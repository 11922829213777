import React from "react";
import classNames from 'classnames';

interface SectionTemplateProps {
  sectionName: string;
  className?: string
  children?: React.ReactNode;
  containerSize?: string;
}

const SectionTemplate: React.FC<SectionTemplateProps> = (props) => {
  const { sectionName, children, containerSize , className } = props;
  return (
    <section className={classNames(
      sectionName,
      className
    )}>
      <div className={classNames(
        'container',
        {'container--small': containerSize === 'small'}
      )}>
        <div className={`${sectionName}__wrap`}>{children}</div>
      </div>
    </section>
  );
};

export default SectionTemplate;
