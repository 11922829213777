import React from "react";
import { OuterPageTemplate } from "../../modules/Layouts";
import {
  AboutItemsList,
  BenefitItems,
  BeveragesBanner,
  ContactBox,
  MainSlider,
  MorePagesList,
  TopTestimonials,
} from "./components";
import { _t } from "../../modules/Common/components/InjectIntlContext";
import { HowItWorks } from "./sections";
import { NavLink } from "react-router-dom";
import { APP_ROUTES } from "../../system/constants/constantsUrl";
import { useMediaQuery } from "react-responsive";
import SectionTemplate from "../../modules/Layouts/SectionTemplate";

const HomePage = () => {
  const mdResolution = useMediaQuery({ query: "(max-width: 991px)" });

  return (
    <OuterPageTemplate>
      <SectionTemplate sectionName="main-banner">
        <MainSlider />
      </SectionTemplate>
      <SectionTemplate sectionName="benefits">
        <h2 className="section-title">{_t("our_benefits")}</h2>
        <BenefitItems />
      </SectionTemplate>
      <HowItWorks />
      <SectionTemplate sectionName="beverages">
        <h2 className="section-title">{_t("income_options")}</h2>
        <BeveragesBanner bannerIdx={0} />
        <MorePagesList />
      </SectionTemplate>
      <SectionTemplate sectionName="contact-section">
        {mdResolution && (
          <h2 className="section-title">{_t("contacts_welcome_title")}</h2>
        )}
        <ContactBox />
      </SectionTemplate>
      <SectionTemplate sectionName="testimonials-preview">
        <h2 className="section-title">{_t("testimonials")}</h2>
        <TopTestimonials />
        <NavLink
          to={APP_ROUTES.testimonials}
          className="income-button testimonials-preview__btn"
        >
          {_t("show_more")}
        </NavLink>
      </SectionTemplate>
      <SectionTemplate sectionName="about-section">
        <div className="about-section__info">
          <h2 className="section-title section-title--left about-section__title">
            {_t("about_income_partners")}
          </h2>
          <AboutItemsList />
        </div>
        <div className="about-section__logo" />
      </SectionTemplate>
    </OuterPageTemplate>
  );
};

export default HomePage;
