import React from "react";
import { Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import { NoData} from "../../../../modules/Common/components/NoData";
import { Prize } from "../utils";
import {_t} from "../../../../modules/Common/components/InjectIntlContext";

const columns = (_t: any): ColumnsType<any> => [
  {
    title: _t("position"),
    dataIndex: "position",
    key: "position",
    render: (val) => (
      <div className={"prize-container"}>
        <span className={"prize-container__place"}>{val}</span>
      </div>
    ),
  },
  {
    title: _t("information"),
    dataIndex: "information",
    key: "information",
  },
];

type PrizesWidgetProps = {
  prizes: Prize[];
};

export function PrizesWidget({ prizes }: PrizesWidgetProps) {
  const translatedColumns = columns(_t);
  return (
    <Table
      columns={translatedColumns}
      rowKey="position"
      dataSource={prizes}
      pagination={false}
      locale={{ emptyText: <NoData /> }}
      size={"small"}
      title={() => _t("prizes")}
    />
  );
}
