import React from "react";
import { _t } from "../../../../modules/Common/components/InjectIntlContext";
import { HowItWorksSliderItem } from "../HowItWorksSliderItem";
import Slider, { Settings } from "react-slick";
import "slick-carousel/slick/slick.css";
import howWorkIcon1 from "../../../../assets/images/outer-pages/how-it-works-slider-img-1.svg";
import howWorkIcon2 from "../../../../assets/images/outer-pages/how-it-works-slider-img-2.svg";
import howWorkIcon3 from "../../../../assets/images/outer-pages/how-it-works-slider-img-3.svg";
import howWorkIcon4 from "../../../../assets/images/outer-pages/how-it-works-slider-img-4.svg";

const howItWorksText = (_t: any) => [
  {
    title: _t("how_1"),
    text: _t("how_work_text_1"),
    icon: howWorkIcon1,
  },
  {
    title: _t("how_2"),
    text: _t("how_work_text_2"),
    icon: howWorkIcon2,
  },
  {
    title: _t("how_3"),
    text: _t("how_work_text_3"),
    icon: howWorkIcon3,
  },
  {
    title: _t("how_4"),
    text: _t("how_work_text_4"),
    icon: howWorkIcon4,
  },
];

const HowItWorksSlider = () => {
  const howItWorksTextTranslate = howItWorksText(_t);
  const settings: Settings = {
    speed: 200,
    arrows: false,
    dots: false,
    variableWidth: true,
    infinite: false,
  };
  return (
    <Slider {...settings} className='how-it-works-slider'>
      {howItWorksTextTranslate.map((item, idx) => {
        return <HowItWorksSliderItem info={item} key={idx} />;
      })}
    </Slider>
  );
};

export default HowItWorksSlider;
