import React from "react";
import classNames from "classnames";

const PresentationVideo = ({ isActive, src }) => {
  return (
    <>
      {isActive && (
        <div className={classNames("presentation-video")}>
          <video controls preload="metadata">
            <source src={src} type="video/mp4" />
          </video>
        </div>
      )}
    </>
  );
};

export default PresentationVideo;
