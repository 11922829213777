import React from "react";
import { _t } from "../../../../../modules/Common/components/InjectIntlContext";
import { APP_ROUTES } from "../../../../../system/constants/constantsUrl";
import { Link } from "react-router-dom";

const SuccessRegistration = () => {
  return (
    <div className="success-sign">
      <h3 className="success-sign__title">{_t("successfully_registered")}</h3>
      <p className="success-sign__text">{_t("successful_registration_message")}</p>
      <Link
        to={APP_ROUTES.login.main}
        className="income-button income-button--full income-button--small-border income-button--small"
      >
        {_t("back_to_login")}
      </Link>
    </div>
  );
};

export default SuccessRegistration;
