import React from "react";
import { OuterPageTemplate, SectionTemplate } from "../../../modules/Layouts";
import { _t } from "../../../modules/Common/components/InjectIntlContext";
import { Spin } from "antd";
import { TextContent } from "../components";
import { useTranslation } from "../../../modules/Landing/apiHooks";
import { getLocale } from "../../../system/helpers/localstorageHelper";

const PostBackOptions = () => {
  const { data, isLoading } = useTranslation({
    language: getLocale(),
    name: "faq-postback",
  });
  return (
    <OuterPageTemplate>
      <SectionTemplate sectionName="terms" className="top-page-indent">
        <h1 className="page-title page-title--center">{_t("postback_options")}</h1>
        <Spin spinning={isLoading}>
          <TextContent text={data?.text} />
        </Spin>
      </SectionTemplate>
    </OuterPageTemplate>
  );
};

export default PostBackOptions;
