import React from "react";

const AuthPasswordIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
    >
      <path
        d="M8 10.5V7.5V7.5C8 5.291 9.791 3.5 12 3.5V3.5C14.209 3.5 16 5.291 16 7.5V7.5V10.5"
        stroke="#828282"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 14.5V17.5"
        stroke="#828282"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17 21.5H7C5.895 21.5 5 20.605 5 19.5V12.5C5 11.395 5.895 10.5 7 10.5H17C18.105 10.5 19 11.395 19 12.5V19.5C19 20.605 18.105 21.5 17 21.5Z"
        stroke="#828282"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default AuthPasswordIcon;
