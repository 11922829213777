import React from "react";
import { useUrlQuery } from "../../../modules/Auth/utils";
import { LoginByUserId } from "./components";
import { useLoginById } from "./api";

const LoginById = () => {
  const query = useUrlQuery();
  const user_id = query.get("user_id");
  return <LoginByUserId activateHook={useLoginById} userData={user_id} />;
};

export default LoginById;
