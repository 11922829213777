import React from "react";
import { Link } from "react-router-dom";
import { _t } from "../../../../modules/Common/components/InjectIntlContext";

interface CommissionsListItemProps {
  title: string,
  text: string,
  path: string
}
const CommissionsListItem: React.FC<CommissionsListItemProps> = (info) => {
  const { title, text, path } = info;
  return (
    <div className="commission-item">
      <h3 className="commission-item__title">{title}</h3>
      <p className="commission-item__text">{text}</p>
      <Link to={path} className='income-button commission-item__btn'>{_t("read more")}</Link>
    </div>
  );
};

export default CommissionsListItem;
