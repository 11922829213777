import React from "react";

const TestimonialItem = ({ title, link, content }) => {
  return (
    <div className="testimonial">
      <div className="testimonial__info">
        <p className="testimonial__title">{title}</p>
        <a
          href={link}
          target="_blank"
          rel="noopener noreferrer nofollow"
          className="testimonial__link"
        >
          {title}
        </a>
      </div>
      <div className="testimonial__content">
        <div
          className="testimonial__text"
          dangerouslySetInnerHTML={{ __html: content }}
        ></div>
      </div>
    </div>
  );
};

export default TestimonialItem;
