import React from "react";
import classNames from "classnames";

interface IncomeButtonProps {
  text?: string;
  className?: string;
  handler?: () => void;
  type?: "button" | "submit" | "reset";
  disabled?: boolean;
  style?: any;
}

const IncomeButton: React.FC<IncomeButtonProps> = (props) => {
  const { text, className, handler, type = "button", disabled, style } = props;
  const incomeButtonClassNames = classNames("income-button", className);
  return (
    <button
      type={type}
      style={style}
      className={incomeButtonClassNames}
      onClick={handler}
      disabled={disabled}
    >
      {text}
    </button>
  );
};

export default IncomeButton;
