import React from "react";

const ContactTgIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="27"
      height="24"
      viewBox="0 0 27 24"
      fill="none"
    >
      <path
        d="M25.9437 0.643118C25.9437 0.64308 25.9436 0.643043 25.9436 0.643018C25.6765 0.412708 25.3515 0.259763 25.0036 0.200743C24.6557 0.14172 24.2983 0.178883 23.9701 0.308207L1.61074 9.08246C1.22107 9.23543 0.891463 9.51031 0.671221 9.86598C0.450978 10.2217 0.351942 10.639 0.388928 11.0556C0.425914 11.4722 0.596933 11.8656 0.876402 12.177C1.15587 12.4884 1.52877 12.701 1.9393 12.7831L7.36763 13.8678C7.51364 13.897 7.61876 14.0252 7.61876 14.1741V21.0038C7.61842 21.3869 7.73191 21.7615 7.94484 22.0801C8.15777 22.3988 8.46057 22.6471 8.8149 22.7937C9.16923 22.9403 9.55914 22.9786 9.93527 22.9037C10.3114 22.8288 10.6568 22.6441 10.9278 22.373L13.8638 19.4404C13.9803 19.324 14.1671 19.3181 14.2908 19.4267L18.8414 23.4267C19.1926 23.7383 19.6458 23.9106 20.1155 23.9112C20.3197 23.911 20.5226 23.8789 20.7168 23.816C21.0372 23.7145 21.3253 23.5309 21.5525 23.2834C21.7796 23.0359 21.9378 22.7333 22.0113 22.4057L26.5683 2.54313C26.6477 2.19965 26.6314 1.84102 26.5213 1.50611C26.4112 1.17122 26.2115 0.87281 25.9437 0.643218C25.9437 0.643193 25.9437 0.643157 25.9437 0.643118ZM8.47377 12.0123C8.40336 12.0631 8.31499 12.0823 8.22985 12.0653L3.36295 11.093C3.05723 11.032 3.01982 10.6099 3.31004 10.496L16.9377 5.14816C17.2795 5.01401 17.5323 5.47737 17.2345 5.69221L8.47377 12.0123ZM10.0902 20.4705C9.89341 20.6671 9.55719 20.5277 9.55719 20.2495V15.9561C9.55719 15.6875 9.87393 15.5442 10.0757 15.7216L12.3623 17.7314C12.4978 17.8506 12.5045 18.0595 12.3768 18.187L10.0902 20.4705ZM20.239 21.4624C20.1861 21.693 19.906 21.7834 19.7283 21.6272L10.4342 13.4574C10.2835 13.3249 10.295 13.0868 10.4577 12.9695L23.8413 3.31445C24.075 3.14585 24.3929 3.35672 24.3285 3.6376L20.239 21.4624Z"
        fill="#B3E8C3"
      />
    </svg>
  );
};

export default ContactTgIcon;
