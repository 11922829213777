import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { updateLocale } from "../../../modules/Locales/actions";
import { Dropdown, Menu } from "antd";
import { getLocale } from "../../../system/helpers/localstorageHelper";
import { flags } from "../../../modules/Locales/constants";
import { LangSwitcherItem } from "../LangSwitcherItem";

interface LangSwitcherProps {
  className?: string;
}

const DropdownLanguagesMenu = () => {
  const { languages } = useSelector((state) => state.locale);
  const dispatch = useDispatch();
  const languageHandler = (lang) => {
    updateLocale(lang.key)(dispatch);
  };
  return (
    <Menu onClick={languageHandler}>
      {languages &&
        Object.values(languages).map((language, index) => {
          return (
            <Menu.Item key={languages[index].code}>
              <LangSwitcherItem
                icon={flags[languages[index].code]}
                text={languages[index].name}
              />
            </Menu.Item>
          );
        })}
    </Menu>
  );
};

const LangSwitcher: React.FC<LangSwitcherProps> = ({ className }) => {
  const locale = getLocale();
  const { languages } = useSelector((state) => state.locale);
  const language = Object.values(languages).filter(
    (item, index) => languages[index].code === locale
  )[0];
  return (
    <Dropdown
      overlay={<DropdownLanguagesMenu />}
      overlayStyle={{ zIndex: 10000 }}
      overlayClassName={"lang-switcher-wrapper"}
      trigger={["click"]}
      className={className}
    >
      <div className="lang-switcher-current">
        <div className="lang-switcher-current__arrow" />
        <LangSwitcherItem
          icon={flags[locale]}
          text={language && language["name"]}
        />
      </div>
    </Dropdown>
  );
};

export default LangSwitcher;
