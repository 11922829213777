import React from "react";
import { Post } from "../utils";
import { Link, generatePath } from "react-router-dom";
import { _t } from "../../../../modules/Common/components/InjectIntlContext";
import {APP_ROUTES} from "../../../../system/constants/constantsUrl";

type NewsItemProps = {
  post: Post;
};
const NewsItem: React.FC<NewsItemProps> = ({ post }) => {
  const newsPath = generatePath(APP_ROUTES.news.show, {slug: post.url});
  return (
    <div className="news-preview">
      <Link to={newsPath} className="news-preview__image">
        <img
          src={`${post.host}${post.options_parsed.image}`}
          alt={post.caption}
        />
      </Link>
      <div className="news-preview__info">
        <Link to={newsPath} className="news-preview__title">
          {post.caption}
        </Link>
        <p
          className="news-preview__text"
          dangerouslySetInnerHTML={{ __html: post.short_text }}
        ></p>
        <Link to={newsPath} className="income-button news-preview__btn">
          {_t("show_more")}
        </Link>
      </div>
    </div>
  );
};

export default NewsItem;
