import React from "react";
import { _t } from "../../../../modules/Common/components/InjectIntlContext";
import { Col, Row } from "antd";
import { BenefitItem } from "../BenefitItem";

const benefitsContent = (_t: any) => [
  {
    title: _t("benefit_1"),
  },
  {
    title: _t("benefit_2"),
  },
  {
    title: _t("benefit_3"),
  },
];

const BenefitItems = () => {
  const benefitsContentTranslated = benefitsContent(_t);
  return (
    <Row gutter={10}>
      {benefitsContentTranslated.map((item, idx) => {
        return (
          <Col span={24} lg={8} key={idx}>
            <BenefitItem itemNum={idx} title={item.title} />
          </Col>
        );
      })}
    </Row>
  );
};

export default BenefitItems;
