import React, { useEffect, useState } from "react";
import classNames from "classnames";

interface HowItWorksImageProps {
  activeIndex: number;
}

const HowItWorksImage: React.FC<HowItWorksImageProps> = (props) => {
  const [isOpened, setIsOpened] = useState(true);
  const { activeIndex } = props;

  const handleImgChange = () => {
    setIsOpened(false);
    setTimeout(() => {
      setIsOpened(true);
    }, 300);
  }

  useEffect(() => {
    handleImgChange();
  }, [activeIndex]);

  return (
    <div
      className={classNames(
        "how-it-works-img",
        { "how-it-works-img--opened": isOpened },
        { [`how-it-works-img--${activeIndex}`]: activeIndex >= 0 }
      )}
    >
      <div className="how-it-works-img__content" />
    </div>
  );
};

export default HowItWorksImage;
