import React from "react";
import { Col, Row } from "antd";
import { APP_ROUTES } from "../../../../system/constants/constantsUrl";
import { _t } from "../../../../modules/Common/components/InjectIntlContext";
import { MorePageItem } from "../MorePageItem";

const morePageListContent = (_t: any) => [
  {
    title: _t("news"),
    path: APP_ROUTES.news.list,
  },
  {
    title: _t("tournaments"),
    path: APP_ROUTES.tournaments.list,
  },
  {
    title: _t("Contacts"),
    path: APP_ROUTES.contacts,
  },
];

const MorePagesList = () => {
  const morePagesListTranslated = morePageListContent(_t);

  return (
    <Row gutter={10} className="more-pages-list">
      {morePagesListTranslated.map((item, idx) => (
        <Col span={24} lg={8} key={idx}>
          <MorePageItem title={item.title} path={item.path} idx={idx} />
        </Col>
      ))}
    </Row>
  );
};

export default MorePagesList;
