import React from "react";
import { Form, Input } from "antd";
import { APP_ROUTES } from "../../../../../system/constants/constantsUrl";
import { history } from "../../../../../system/store";
import { Link } from "react-router-dom";
import { _t } from "../../../../../modules/Common/components/InjectIntlContext";
import { AuthMailIcon } from "../../../components/Icons";
import { IncomeButton } from "../../../components";

interface PasswordRestoreFormProps {
  submit?: any;
  accessCheck?: boolean;
}
const PasswordRestoreForm: React.FC<PasswordRestoreFormProps> = ({
  submit,
  accessCheck,
}) => {
  return (
    <Form onFinish={submit} className="auth-form">
      <Form.Item
        name="email"
        rules={[
          {
            type: "email",
            message: _t("not_valid_email_message"),
          },
          {
            required: true,
            message: _t("input_email_message"),
          },
        ]}
        className="auth-form__item"
      >
        <div className="auth-input-box">
          <Input placeholder={_t("Email")} />
          <div className="auth-input-box__icon">
            <AuthMailIcon />
          </div>
        </div>
      </Form.Item>
      <div className="auth-form__buttons">
        <IncomeButton
          type="submit"
          text={_t("Remind")}
          className="income-button--small auth-form__btn"
        />
        {accessCheck ? (
          <IncomeButton
            handler={() => history.goBack()}
            className="income-button income-button--small-border income-button--small auth-form__btn"
            style={{ marginTop: "20px" }}
            text={_t("Back")}
          />
        ) : (
          <>
            <p className="auth-form__divider-txt">{_t("no_acc_msg")}</p>
            <Link
              to={APP_ROUTES.registration.main}
              className="income-button income-button--small-border income-button--small auth-form__btn"
            >
              {_t("regs")}
            </Link>
          </>
        )}
      </div>
    </Form>
  );
};

export default PasswordRestoreForm;
