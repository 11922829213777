import React from 'react';
import {Link} from "react-router-dom";
import logo from '../../../../assets/images/logo.svg'
const MainLogo = () => {
  return (
    <Link to='/' className='main-logo'>
      <img src={logo} alt="income" loading='lazy'/>
    </Link>
  );
};

export default MainLogo;