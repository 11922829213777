import React from "react";
import { OuterPageTemplate, SectionTemplate } from "../../../modules/Layouts";
import { _t } from "../../../modules/Common/components/InjectIntlContext";
import { CommissionContent } from "./components";

const points = (_t: any) => [
  {
    text: _t("cpa_1"),
  },
  {
    text: _t("cpa_2"),
  },
  {
    text: _t("cpa_3"),
  },
  {
    text: _t("cpa_4"),
  },
  {
    text: _t("cpa_5"),
  },
  {
    text: _t("cpa_6"),
  },
  {
    text: _t("cpa_7"),
  },
];
const Cpa = () => {
  const translatedPoints = points(_t);
  return (
    <OuterPageTemplate>
      <SectionTemplate
        sectionName="commission-section"
        className="top-page-indent"
      >
        <CommissionContent title={_t("cpa_title")} points={translatedPoints} />
      </SectionTemplate>
    </OuterPageTemplate>
  );
};

export default Cpa;
