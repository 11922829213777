import React from 'react';
import {Col, Row} from "antd";
import {_t} from "../../../../modules/Common/components/InjectIntlContext";
import {AboutItem} from "../AboutItem";
const aboutListContent = (_t: any) => [
  {
    title: _t("about_us_title_1"),
    text: _t("about_us_text_1")
  },
  {
    title: _t("about_us_title_2"),
    text: _t("about_us_text_2")
  },
  {
    title: _t("about_us_title_3"),
    text: _t("about_us_text_3")
  },
  {
    title: _t("about_us_title_4"),
    text: _t("about_us_text_4")
  },
  {
    title: _t("about_us_title_5"),
    text: _t("about_us_text_5")
  },
];
const AboutItemsList = () => {
  const translatedItems = aboutListContent(_t);
  return (
    <Row gutter={20} className='about-items-list'>
      {
        translatedItems.map((item,idx)=> {
          const lastItem = idx === translatedItems.length - 1;
          return(
            <Col key={idx} lg={lastItem ? 16 : 8} span={24}>
              <AboutItem title={item.title} text={item.text} index={idx}/>
            </Col>
          )
        })
      }
    </Row>
  );
};

export default AboutItemsList;