import React from "react";
import { Tournament } from "../utils";
import { Link } from "react-router-dom";

type TournamentPreviewProps = {
  tournament: Tournament;
};

const TournamentPreview: React.FC<TournamentPreviewProps> = ({
  tournament,
}) => {
  return (
    <div className="tournament-preview">
      <Link
        to={`/tournament/${tournament.id}`}
        className="tournament-preview__image"
      >
        <img src={`${tournament.host}${tournament.image}`} alt="" />
      </Link>
      <div className="tournament-preview__content">
        <h4 className="tournament-preview__title">
          <Link to={`/tournament/${tournament.id}`}>{tournament.title}</Link>
        </h4>
        <p className="tournament-preview__date">
          {tournament.date_from_parsed} - {tournament.date_to_parsed}
        </p>

        <div
          className="tournament-preview__description"
          dangerouslySetInnerHTML={{ __html: tournament.short_description }}
        ></div>
      </div>
    </div>
  );
};

export default TournamentPreview;
