import React from "react";
import { OuterPageTemplate, SectionTemplate } from "../../../modules/Layouts";
import { _t } from "../../../modules/Common/components/InjectIntlContext";
import { PARTNERS_LIST } from "./constants";

const Partners = () => {
  return (
    <OuterPageTemplate>
      <SectionTemplate sectionName="partners-page top-page-indent">
        <h1 className="page-title">{_t("partners_page_title")}</h1>
        <p className="partners-page__text">{_t("partners_page_text")}</p>
        <div className="partners-list">
          {PARTNERS_LIST(_t).map((item: any, idx) => {
            return (
              <div className="partners-list__item" key={idx}>
                <div
                  className={`partner-list-item ${
                    item?.isDisabled ? "partner-list-item--disabled" : ""
                  }`}
                >
                  <div className="partner-list-item__img">
                    <img src={item.logo} alt="" />
                  </div>
                  <h3 className="partner-list-item__title">{item.title}</h3>
                  <p className="partner-list-item__text">{item.text}</p>
                  <a
                    href={item.link_path}
                    target="_blank"
                    rel="noreferrer"
                    className="income-button income-button--border"
                  >
                    {_t("partner_list_btn_text")}
                  </a>
                </div>
              </div>
            );
          })}
        </div>
      </SectionTemplate>
    </OuterPageTemplate>
  );
};

export default Partners;
