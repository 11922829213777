import React from "react";

interface LangSwitcherItemProps {
  icon: string;
  text: string;
}
const LangSwitcherItem: React.FC<LangSwitcherItemProps> = (props) => {
  const { icon, text } = props;
  return (
    <button type="button" className="lang-switcher-item">
      {/*<span className="lang-switcher-item__icon">*/}
      {/*  <img src={icon} alt={text} />*/}
      {/*</span>*/}
      <span className="lang-switcher-item__text">{text}</span>
    </button>
  );
};

export default LangSwitcherItem;
