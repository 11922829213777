import React, { useState } from "react";
import classNames from "classnames";
import { _t } from "../../../../modules/Common/components/InjectIntlContext";

const howItWorksText = (_t: any) => [
  {
    title: _t("how_1"),
  },
  {
    title: _t("how_2"),
  },
  {
    title: _t("how_3"),
  },
  {
    title: _t("how_4"),
  },
];

interface HowItWorksListProps {
  onClick: (idx: number) => void;
  activeIndex: number;
}

const HowItWorksList: React.FC<HowItWorksListProps> = (props) => {
  const { onClick, activeIndex } = props;
  const howItWorksTextTranslate = howItWorksText(_t);

  return (
    <ul className="how-it-works-list">
      {howItWorksTextTranslate.map((item, idx) => (
        <li
          key={idx}
          className={classNames("how-it-works-list__item", {
            "how-it-works-list__item--active": idx === activeIndex,
          })}
        >
          <button
            onClick={() => onClick(idx)}
            type="button"
            className="how-it-works-list__button"
          >
            {item.title}
          </button>
        </li>
      ))}
    </ul>
  );
};

export default HowItWorksList;
