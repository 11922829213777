import {
  loginByHash,
  rigister,
  activateUser,
  resetPassword,
  restorePassword,
  loginById,
} from "./api";
import { useMutation, MutationOptions } from "react-query";
import { REGISTRATION_SUCCESS_MESSAGE } from "../utils";
import { _t } from "../../../../modules/Common/components/InjectIntlContext";
import { notifyMap, notify } from "../../../../common/helpers";

export const useLoginById = (options: MutationOptions<any, any>) => {
  return useMutation(loginById, options);
};

export const useLoginByHash = (options: MutationOptions<any, any>) => {
  return useMutation(loginByHash, options);
};

export const useRegister = (options: MutationOptions<any, any> = {}) => {
  const successfullyRegistered = _t("successfully_registered");
  return useMutation(rigister, {
    onSuccess: (data, variables) => {
      if (data.status === REGISTRATION_SUCCESS_MESSAGE) {
        notify("success", successfullyRegistered);
      } else {
        notifyMap("error", data.data.messages);
      }
    },
  });
};

export const useActivateUser = (options: MutationOptions<any, any> = {}) => {
  return useMutation(activateUser, options);
};
export const useResetPassword = (options: MutationOptions<any, any> = {}) => {
  return useMutation(resetPassword, options);
};
export const useRestorePassword = (options: MutationOptions<any, any> = {}) => {
  const checkEmail = _t("Please visit your email and follow the instruction");
  return useMutation(restorePassword, {
    onSuccess: (data, variables) => {
      notify("success", checkEmail);
    },
    onError: (error: any) => {
      notify("error", error.response?.data?.message);
    },
  });
};
