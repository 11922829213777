import axiosClient from "../../../../system/helpers/axiosConfig";

interface Translation {
  faq_id: number;
  question: string;
  answer: string;
}

export interface Faq {
  id: number;
  translations: Translation[];
}

export interface FaqResponse {
  current_category: string;
  faq: Faq[];
}

export interface GetFaqRequest {
  category: string | "general" | "finance" | "technical";
  language: string;
}

export const getFaqs = (params: GetFaqRequest) => {
  return axiosClient
    .get<FaqResponse>(`/api/faqs`, { params })
    .then((r) => r.data);
};
