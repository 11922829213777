import React, { useState } from "react";
import { Filters } from "../../../../../../../modules/Common/components/Filters";
import { useForm } from "antd/lib/form/Form";
import { Col, Form, Row, Select } from "antd";
import {
  PartnersSearch,
  ProgramsSelect,
  ProgramTypesSelect,
} from "../../../../../../../common/components";
import { _t } from "../../../../../../../modules/Common/components/InjectIntlContext";
import { useGetDataWithoutParams } from "../../../../../../../common/hooks/useGetDataWithoutParams";
import {
  API_GET_BRANDS_LIST,
  API_KEY_GET_BRANDS_LIST,
} from "../../../GamersFTDReport/utils";
import { selectDataProps } from "../../../../../Chat/helpers";
import DateRangePicker from "../../../../../../../common/components/DateRangePicker/DateRangePicker";
import {
  datePickerDividerValidation,
  setFormInputValue,
} from "../../../../../../../common/helpers";
import { ADDITIONAL_CURRENCY_SYMBOL_DATE } from "../../../../../../../system/config";

const ReportFilters = ({ setFilters, initialValues, excelButton }) => {
  const [form] = useForm();
  const {
    data: brandsData,
    isLoading: isBrandsLoading,
  } = useGetDataWithoutParams(API_KEY_GET_BRANDS_LIST, API_GET_BRANDS_LIST);
  const projectsOptions =
    brandsData &&
    Object.keys(brandsData.projects).map((item) => ({
      label: brandsData.projects[item].name,
      value: brandsData.projects[item].id,
    }));
  const { Option } = Select;
  const [selectData, setSelectData] = useState<selectDataProps>();
  const [searchValue, setSearchValue] = useState<any[]>();
  const clearSearch = () => {
    setSearchValue([]);
    setSelectData({ data: [] });
  };
  const sendFilters = (values) => {
    if (values.projects) values.projects.forEach((item) => +item);
    if (searchValue) values.partners = searchValue.map((item) => +item);
    setFilters(values);
  };
  return (
    <Filters
      form={form}
      onApply={sendFilters}
      initialValues={initialValues}
      mainFilters={
        <Row gutter={[10, 10]}>
          <Col xs={24} md={12} xl={6}>
            <Form.Item name="date">
              <DateRangePicker
                onChange={(dates) => {
                  setFormInputValue(
                    "date",
                    {
                      from: dates[0],
                      to: dates[1],
                    },
                    form
                  );
                }}
                disabledDate={(current, dateRangeValue) =>
                  datePickerDividerValidation(
                    current,
                    dateRangeValue,
                    ADDITIONAL_CURRENCY_SYMBOL_DATE
                  )
                }
              />
            </Form.Item>
          </Col>
          <Col xs={24} md={12} xl={6}>
            <Form.Item name="date2">
              <DateRangePicker
                onChange={(dates) => {
                  setFormInputValue(
                    "date2",
                    {
                      from: dates[0],
                      to: dates[1],
                    },
                    form
                  );
                }}
                disabledDate={(current, dateRangeValue) =>
                  datePickerDividerValidation(
                    current,
                    dateRangeValue,
                    ADDITIONAL_CURRENCY_SYMBOL_DATE
                  )
                }
              />
            </Form.Item>
          </Col>
          <Col xs={24} md={12} xl={6}>
            <ProgramsSelect />
          </Col>
          <Col xs={24} md={12} xl={6}>
            <ProgramTypesSelect />
          </Col>
          <Col span={24} xl={6}>
            <Form.Item name="partners" label={_t("partner")}>
              <PartnersSearch
                selectData={selectData}
                setSelectData={setSelectData}
                searchValue={searchValue}
                setSearchValue={setSearchValue}
                Option={Option}
                clearSearch={clearSearch}
                isMultiple
              />
            </Form.Item>
          </Col>
          <Col xs={24} md={4} xl={2}>
            {excelButton}
          </Col>
        </Row>
      }
    />
  );
};

export default ReportFilters;
