import React from "react";
import { Link } from "react-router-dom";
import { _t } from "../../../../modules/Common/components/InjectIntlContext";
import { APP_ROUTES } from "../../../../system/constants/constantsUrl";

const MainBannerItem = ({ info }) => {
  const { title, text, title_2 } = info;
  return (
    <div className="main-banner-item">
      <div className="main-banner-item__info">
        <h2 className="main-banner-item__title">
          {title}
          {title_2 && (
            <>
              <br /> {title_2}
            </>
          )}
        </h2>
        <p className="main-banner-item__text">{text}</p>
        <Link
          to={APP_ROUTES.partners}
          className="income-button main-banner-item__join"
        >
          {_t("join_us")}
        </Link>
      </div>
      <div className="main-banner-item__image main-banner-item__image--preview" />
    </div>
  );
};

export default MainBannerItem;
