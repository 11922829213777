import React from "react";
import {
  Copyright,
  FooterNav,
  MainLogo,
} from "../../../../pages/outer/components";
import { useMediaQuery } from "react-responsive";

const Footer = () => {
  const xsResolution = useMediaQuery({ query: "(max-width: 567px)" });

  return (
    <footer className="footer">
      <div className="container">
        <div className="footer__wrap">
          <div className="footer__bottom">
            {xsResolution && <MainLogo />}
            <Copyright />
          </div>
          <FooterNav />
        </div>
      </div>
    </footer>
  );
};

export default Footer;
