import notification from "antd/lib/notification";

export const notify = (type = "error", message, config = {}) =>
  notification[type]({
    ...config,
    message: message,
    placement: "topRight",
    duration: 1.5,
    maxCount: 3,
  });

export const notifyMap = (type = "error", messages, config = {}) =>
  Object.keys(messages).map((key) => {
    notify(type, messages[key], config);
  });
