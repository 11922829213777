import React from "react";
import { _t } from "../../../../modules/Common/components/InjectIntlContext";
import { ContactForm } from "../ContactForm";
import { useMediaQuery } from "react-responsive";

const ContactBox = () => {
  const mdResolution = useMediaQuery({ query: "(max-width: 991px)" });

  return (
    <div className="contact-box">
      <p className="contact-box__title">{_t("contacts_welcome_title")}</p>
      <p className="contact-box__text">
        {!mdResolution ? _t("contacts_welcome_message") : _t("leave_contacts")}
      </p>
      <ContactForm />
      <div className="contact-box__image" />
    </div>
  );
};

export default ContactBox;
