import React from "react";
import classNames from "classnames";

interface PresentationNavItemProps {
  handleTabSwitch: (val) => void;
  icon: string;
  text: string;
  isActive: boolean;
  idx: number;
}
const PresentationNavItem: React.FC<PresentationNavItemProps> = ({
  handleTabSwitch,
  icon,
  text,
  isActive,
  idx,
}) => {
  return (
    <button
      type="button"
      onClick={() => handleTabSwitch(idx)}
      className={classNames("presentation-nav-item", {
        "presentation-nav-item--active": isActive,
      })}
    >
      <img src={icon} alt="" className="presentation-nav-item__icon" />
      {text}
    </button>
  );
};

export default PresentationNavItem;
