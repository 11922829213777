import React from "react";

const ContactMailIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="29"
      height="21"
      viewBox="0 0 29 21"
      fill="none"
    >
      <path
        d="M25.1887 0.962769H3.81082C3.00085 0.962769 2.22406 1.28171 1.65133 1.84944C1.07859 2.41717 0.756836 3.18717 0.756836 3.99006V17.6129C0.756836 18.4157 1.07859 19.1857 1.65133 19.7535C2.22406 20.3212 3.00085 20.6401 3.81082 20.6401H25.1887C25.9987 20.6401 26.7755 20.3212 27.3482 19.7535C27.9209 19.1857 28.2427 18.4157 28.2427 17.6129V3.99006C28.2427 3.18717 27.9209 2.41717 27.3482 1.84944C26.7755 1.28171 25.9987 0.962769 25.1887 0.962769ZM10.4013 12.0366L12.8476 14.1557C13.3133 14.5583 13.9027 14.7581 14.4998 14.7581C15.0968 14.7581 15.6862 14.5568 16.152 14.1557L18.5982 12.0366L18.5417 12.0926L24.1091 17.6113H4.8904L10.4578 12.0926L10.4013 12.0366ZM3.81082 16.5427V6.32107L9.31105 11.0906L3.81082 16.5427ZM19.6885 11.0906L25.1887 6.32107V16.5382L19.6885 11.0906ZM25.1887 3.99006V4.30792L15.1472 13.0174C14.9633 13.1628 14.735 13.242 14.4998 13.242C14.2645 13.242 14.0362 13.1628 13.8523 13.0174L3.81082 4.30792V3.99006H25.1887Z"
        fill="#B3E8C3"
      />
    </svg>
  );
};

export default ContactMailIcon;
