import React, { useState } from "react";
import { Link } from "react-router-dom";
import { _t } from "../../../../modules/Common/components/InjectIntlContext";
import { HowItWorksImage, HowItWorksList } from "../../components";
import { APP_ROUTES } from "../../../../system/constants/constantsUrl";
import { useMediaQuery } from "react-responsive";
import { HowItWorksSlider } from "../../components/HowItWorksSlider";
import { SectionTemplate } from "../../../../modules/Layouts";

const HowItWorks = () => {
  const mdResolution = useMediaQuery({ query: "(max-width: 991px)" });

  const [activeIndex, setActiveIndex] = useState(0);
  const handleChangeSlide = (idx) => {
    setActiveIndex(idx);
  };
  return (
    <SectionTemplate sectionName="how-it-works">
      <div className="how-it-works__info">
        <h2 className="section-title section-title--left section-title--dark how-it-works__title">
          {_t("how_it_works")}
        </h2>
        <h4 className="how-it-works__sub-title">
          {_t("how_it_works_subtitle")}
        </h4>
        {!mdResolution && (
          <HowItWorksList
            onClick={handleChangeSlide}
            activeIndex={activeIndex}
          />
        )}
        {mdResolution && <HowItWorksSlider />}
      </div>
      <Link
        to={APP_ROUTES.partners}
        className="income-button income-button--dark how-it-works__btn"
      >
        {_t("get started")}
      </Link>
      {!mdResolution && <HowItWorksImage activeIndex={activeIndex} />}
    </SectionTemplate>
  );
};

export default HowItWorks;
