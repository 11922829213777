import React from "react";
import classNames from "classnames";

interface BurgerMenuProps {
  onClick: () => void;
  isOpened: boolean;
}

const BurgerMenu: React.FC<BurgerMenuProps> = ({ onClick, isOpened }) => {
  const burgerMenuClassNames = classNames("burger-menu", {
    "burger-menu--opened": isOpened,
  });
  return <button type="button" className={burgerMenuClassNames} onClick={onClick} />;
};

export default BurgerMenu;
