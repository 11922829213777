import React from "react";
import classNames from "classnames";

interface BenefitItemProps {
  title: string,
  itemNum: number,
}
const BenefitItem: React.FC<BenefitItemProps> = ({ title, itemNum }) => {
  const benefitItemClassNames = classNames(
    "benefit-item",
    `benefit-item--${itemNum}`
  );
  return (
    <div className={benefitItemClassNames}>
      <div className='benefit-item__image' />
      <h4 className='benefit-item__title'>{title}</h4>
    </div>
  );
};

export default BenefitItem;
