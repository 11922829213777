import React from "react";
import { PresentationNavItem } from "../PresentationNavItem";
import { icon_1, icon_2, icon_3 } from "../../icons";
import { _t } from "../../../../../modules/Common/components/InjectIntlContext";

const tabs = (_t: any) => [
  {
    title: _t("presentation_nav_title_1"),
    icon: icon_1,
  },
  {
    title: _t("presentation_nav_title_2"),
    icon: icon_2,
  },
  {
    title: _t("presentation_nav_title_3"),
    icon: icon_3,
  },
];

const PresentationNav = ({ activeTab, setActiveTab }) => {
  const getTabs = tabs(_t);

  const tabItem = (item, idx) => (
    <li className="presentation-nav__item" key={idx}>
      <PresentationNavItem
        handleTabSwitch={setActiveTab}
        icon={item.icon}
        text={item.title}
        isActive={idx === activeTab}
        idx={idx}
        key={idx + item.title}
      />
    </li>
  );

  return <ul className="presentation-nav">{getTabs.map(tabItem)}</ul>;
};

export default PresentationNav;
