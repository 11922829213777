import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import * as actionTypes from "../../utils";
import { Spin } from "antd";
import { _t } from "../../../../../modules/Common/components/InjectIntlContext";

const ActivateUser = ({ userData, activateHook }) => {
  const dispatch = useDispatch();

  const [activate, { isLoading, error }] = activateHook({
    onSuccess: (res) =>
      dispatch({ type: actionTypes.AUTH_SUCCESS, payload: res.data }),
  });

  useEffect(() => {
    if (userData) {
      activate(userData);
    }
  }, [userData]);
  return (
    <>
      <div className="auth-box">
        {!error && userData && (
          <Spin spinning={isLoading}>
            <p className="auth-box__sub-title">
              {_t("Authentication in progress, please wait...")}
            </p>
          </Spin>
        )}
        {error && (
          <p className="auth-box__sub-title">{_t("Something went wrong")}</p>
        )}
        {!userData && (
          <p className="auth-box__sub-title">
            {_t("Url is not valid, please contact your admin")}
          </p>
        )}
      </div>
    </>
  );
};

export default ActivateUser;
