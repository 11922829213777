const projectEnv = process.env.NODE_ENV;
const developmentUrl = "https://api-master.income.go.dogt.xyz";
const localDevelopmentUrl = "https://income-partners-5.loc";

let backendUrl = null;

switch (projectEnv) {
  case "development":
    backendUrl =
      localDevelopmentUrl ||
      `${window.location.protocol}//${window.location.hostname}`;
    break;
  case "local-development":
    backendUrl =
      localDevelopmentUrl ||
      `${window.location.protocol}//${window.location.hostname}`;
    break;
  case "stage":
    backendUrl = `${window.location.protocol}//${window.location.hostname}`;
    break;
  case "dotools":
    backendUrl = `${window.location.protocol}//${window.location.hostname}`;
    break;
  default:
    backendUrl = `${window.location.protocol}//api-${window.location.hostname}`;
    break;
}

if (process.env.REACT_APP_INCOME_BACKEND_URL) {
  backendUrl = process.env.REACT_APP_INCOME_BACKEND_URL;
}

export default backendUrl;

