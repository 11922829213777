import React from "react";
import { Tournament } from "../utils";
import { Prize, Result } from "../../../../modules/Tournaments/constants";
import { Col, Row } from "antd";
import { PrizesWidget } from "./PrizesWidget";
import { ResultsWidget } from "./ResultsWidget";

type TournamentViewProps = {
  tournament: Tournament;
  results: Result[];
  prizes: Prize[];
};

const TournamentContent: React.FC<TournamentViewProps> = ({
  tournament,
  results,
  prizes,
}) => {
  return (
    <>
      <h1 className="page-title page-title--center">{tournament.title}</h1>
      <div className="tournament-preview">
        <div className="tournament-preview__image tournament-preview__image--default">
          <img src={`${tournament.host}${tournament.image}`} alt="" />
        </div>
        <div className="tournament-preview__content">
          <p className="tournament-preview__date">
            {tournament.date_from_parsed} - {tournament.date_to_parsed}
          </p>
          <Row className="tournament-preview__result" gutter={20}>
            <Col lg={12} span={24}>
              <PrizesWidget prizes={prizes} />
            </Col>
            <Col lg={12} span={24}>
              <ResultsWidget results={results} />
            </Col>
          </Row>
          <div
            className="tournament-preview__description"
            dangerouslySetInnerHTML={{ __html: tournament.content }}
          ></div>
        </div>
      </div>
    </>
  );
};

export default TournamentContent;
