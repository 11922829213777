import React from "react";
import { ErrorTemplate } from "../../../modules/Layouts";
import logo from "../../../assets/images/outer-pages/big-logo.svg";
import { Link } from "react-router-dom";
import { _t } from "../../../modules/Common/components/InjectIntlContext";
const Page404 = () => {
  return (
    <ErrorTemplate>
      <div className="error-page-content">
        <div className="error-page-content__main error-page-content__main--404">
          <div className="error-page-content__main-logo">
            <img src={logo} alt="income" />
          </div>
        </div>
        <h1 className="error-page-content__title">{_t("error_404_title")}</h1>
        <p className="error-page-content__text">{_t("error_404_text")}</p>
        <div className="error-page-content__btn">
          <Link to="/" className="income-button">
            {_t("back_to_main")}
          </Link>
        </div>
      </div>
    </ErrorTemplate>
  );
};

export default Page404;
