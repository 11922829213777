import { useMutation, useQueryCache } from "react-query";
import { API_KEY_GET_TRANSLATION_LIST } from "../constants";
import { _t } from "../../../../../modules/Common/components/InjectIntlContext";
import { notify } from "../../../../../common/helpers";
import { postTranslationImportExcel } from "../api";

export const usePostTranslationImportExcel = () => {
  const successMessage = _t("successfully_sent");
  const queryCache = useQueryCache();

  return useMutation(postTranslationImportExcel, {
    onSuccess: (data, variables) => {
      queryCache.invalidateQueries(API_KEY_GET_TRANSLATION_LIST);
      notify("success", successMessage);
    },
  });
};
