import React from "react";
import EmailLogo from "../../../images/social/mail.svg";
import SkypeLogo from "../../../images/social/skype.svg";
import TelegramLogo from "../../../images/social/telega.svg";

const SocialNetworks = () => {
  return (
    <section className="networks landing__networks">
      <div className="networks__links-list">
        <div className="networks__links-item">
          <a
            href="https://tlgrm.biz/income_partners"
            target="_blank"
            className="networks__link"
            rel="noopener noreferrer"
          >
            <img
              src={TelegramLogo}
              className="networks__link-img"
              alt="telegram"
            />
          </a>
        </div>
        <div className="networks__links-item">
          <a
            href="skype:live:.cid.876280c7ef1edbaf?chat"
            target="_blank"
            className="networks__link"
            rel="noopener noreferrer"
          >
            <img src={SkypeLogo} className="networks__link-img" alt="skype" />
          </a>
        </div>
        <div className="networks__links-item">
          <a
            href="mailto:support@income.partners"
            target="_blank"
            className="networks__link"
            rel="noopener noreferrer"
          >
            <img src={EmailLogo} className="networks__link-img" alt="e-mail" />
          </a>
        </div>
      </div>
    </section>
  );
};

export default SocialNetworks;
