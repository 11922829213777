import React from "react";

interface AboutItemProps {
  title: string;
  text: string;
  index: number;
}

const AboutItem: React.FC<AboutItemProps> = (props) => {
  const { title, text, index } = props;
  return (
    <div className="about-item">
      <h4 className="about-item__title">{title}</h4>
      <p className="about-item__text">{text}</p>
      <div className={`about-item__icon about-item__icon--${index}`} />
    </div>
  );
};

export default AboutItem;
