import React, { useState } from "react";
import { isAuthorized } from "../../../../system/helpers/localstorageHelper";

import {
  HeaderNav,
  LoginButtons,
  MainLogo,
} from "../../../../pages/outer/components";
import { LangSwitcher } from "../../../../common/components";
import { useMediaQuery } from "react-responsive";
import { BurgerMenu } from "../../../../pages/outer/components/BurgerMenu";
import classNames from "classnames";
import { APP_ROUTES } from "../../../../system/constants/constantsUrl";
import { _t } from "../../../Common/components/InjectIntlContext";
import { Link } from "react-router-dom";

const Header = () => {
  const [menuOpened, setMenuOpened] = useState(false);
  const mdResolution = useMediaQuery({ query: "(max-width: 991px)" });
  const isLogged = isAuthorized();

  const handleMenuToggle = () => {
    setMenuOpened(!menuOpened);
  };

  return (
    <header className={classNames("header", { "header--logged": isLogged })}>
      <div className="container">
        <div className="header__wrap">
          {mdResolution && (
            <BurgerMenu onClick={handleMenuToggle} isOpened={menuOpened} />
          )}
          <MainLogo />
          {!mdResolution && (
            <>
              <HeaderNav isLogged={isLogged} />
              <LangSwitcher className="header__lang" />
            </>
          )}
          <Link to={APP_ROUTES.partners} className="income-button">
            {_t("select_partner")}
          </Link>
        </div>
      </div>
      {mdResolution && (
        <div
          className={classNames("header__mobile-drop", {
            "header__mobile-drop--opened": menuOpened,
          })}
        >
          <HeaderNav isLogged={isLogged} />
          <LangSwitcher />
        </div>
      )}
    </header>
  );
};

export default Header;
