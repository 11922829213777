import React from "react";
import { Link } from "react-router-dom";
import { _t } from "../../../../modules/Common/components/InjectIntlContext";
import { APP_ROUTES } from "../../../../system/constants/constantsUrl";

interface CommissionContentProps {
  title: string;
  points: { text: string }[];
}

const CommissionContent: React.FC<CommissionContentProps> = (info) => {
  const { title, points } = info;
  console.log(points);
  return (
    <div className="commission-content">
      <h1 className="page-title page-title--center">{title}</h1>
      <ul className="commission-content__list">
        {points.map((item, idx) => (
          <li key={idx} className="commission-content__item">
            {item.text}
          </li>
        ))}
      </ul>
      <Link
        to={APP_ROUTES.commissions.commission}
        className="income-button commission-content__btn"
      >
        {_t("back_to_commission")}
      </Link>
    </div>
  );
};

export default CommissionContent;
