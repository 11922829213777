import React from "react";
import { Route, Switch } from "react-router-dom";
import { UserActivation, ResetPassword } from "../modules/Auth/Pages";
import User from "../modules/User/components";
import Dashboard from "../modules/Dashboard/components/index.tsx";
import Promo from "../modules/Promo/components/index";
import AllComponents from "../modules/Common/AllComponents";
import Trackers from "../modules/Trackers/pages/Trackers";
import Payments from "../modules/Payments/pages/Payments";
import Affiliates from "../modules/Affiliates/pages/Affiliates";
import Costs from "../modules/Costs/components";
import Brands from "../modules/Brands/pages/brands";
import UsersAdmin from "../modules/Users/pages/UsersAdmin";
import { UsersProfile } from "../modules/Users/pages/UsersProfile";
import { APP_ROUTES } from "./constants/constantsUrl";
import UsersCreate from "../modules/Users/pages/UsersCreate";
import {
  HomePage,
  Testimonials,
  News,
  Article,
  Contacts,
  Terms,
  PostBackOptions,
  Commissions,
  RevShare,
  SubAffiliate,
  Cpa,
  Tournaments,
  TournamentArticle,
  Presentation,
  Partners,
  FAQ,
} from "../pages/outer";
import {
  PasswordRestore,
  SignIn,
  SignUp,
  LoginByHash,
  LoginById,
} from "../pages/outer/Auth";
import {
  AffiliateBrandItemEdit,
  AffiliateBrands,
  AffiliateLanding,
  AffiliateLandingCreate,
  AffiliateProgramEdit,
  ChatCreateForm,
  ChatInnerPage,
  ChatsListAdmin,
  ChatsListUser,
  ChatCreateFormAdmin,
  ChatInnerPageAdmin,
  GamersFTDReport,
  GamersReport,
  AffiliatesReport,
  ManageableNotificationsList,
  NotificationEdit,
  NotificationCreate,
  ManageableList,
  ManageableItemEdit,
  ManageableListAddUser,
  TestimonialList,
  TestimonialEdit,
  TestimonialCreate,
  TranslationList,
  TranslationKeyCreate,
  TranslationEdit,
  TranslationImportExcel,
  FaqList,
  FaqEdit,
  FaqCreate,
  AffiliateDifferenceReport,
  GeoReport,
  FrodersList,
  FroderEdit,
  FroderBulk,
  AffiliatesGeoReport,
} from "../pages/inner";
import AffiliateProgramCreate from "../pages/inner/AffiliatesPrograms/AffiliateBrands/AffiliateProgramCreate";
import { Page404 } from "../pages/error";
import PrivateRoute from "./helpers/PrivateRoute";
import { permissions } from "./constants/roles";
import ManageableItemCreate from "../pages/inner/Manage/Managers/pages/ManageableItemCreate/ManageableItemCreate";

export default (
  <Switch>
    <Route path={APP_ROUTES.login.main} render={() => <SignIn />} />
    <Route path={APP_ROUTES.login.hash} component={LoginByHash} />
    <Route
      path={APP_ROUTES.user.restore_password}
      component={PasswordRestore}
    />
    <Route path={APP_ROUTES.user.reset_password} component={ResetPassword} />
    <Route path={APP_ROUTES.registration.main} component={SignUp} />
    <Route path={APP_ROUTES.registration.activate} component={UserActivation} />
    <Route exact path={APP_ROUTES.brands.index} component={Brands} />
    <Route path={APP_ROUTES.commissions.revshare} component={RevShare} />
    <Route path={APP_ROUTES.commissions.cpa} component={Cpa} />
    <Route
      path={APP_ROUTES.commissions.subaffiliate}
      component={SubAffiliate}
    />
    <Route path={APP_ROUTES.commissions.commission} component={Commissions} />
    <Route path={APP_ROUTES.contacts} component={Contacts} />
    <Route path={APP_ROUTES.terms} component={Terms} />
    <Route path={APP_ROUTES.postback_options} component={PostBackOptions} />
    <Route exact path={APP_ROUTES.testimonials} component={Testimonials} />
    <Route path={APP_ROUTES.news.list} component={News} />
    <Route path={APP_ROUTES.news.show} component={Article} />
    <Route path={APP_ROUTES.tournaments.list} component={Tournaments} />
    <Route path={APP_ROUTES.tournaments.show} component={TournamentArticle} />
    <Route path={APP_ROUTES.presentation} component={Presentation} />
    <Route path={APP_ROUTES.partners} component={Partners} />
    <PrivateRoute path={APP_ROUTES.dashboard.general} component={Dashboard} />
    <PrivateRoute path={APP_ROUTES.promo.create} component={Promo} />
    <PrivateRoute path={APP_ROUTES.trackers} component={Trackers} />
    <PrivateRoute path={APP_ROUTES.payments} component={Payments} />
    <PrivateRoute path={APP_ROUTES.affiliates} component={Affiliates} />
    <PrivateRoute path={APP_ROUTES.user.main} component={User} />
    <PrivateRoute path={APP_ROUTES.all_components} component={AllComponents} />
    <PrivateRoute
      path={APP_ROUTES.costs.main}
      component={Costs}
      permissions={permissions.dashboard.header.costs}
    />

    <PrivateRoute
      exact
      path={APP_ROUTES.manage.users}
      component={UsersAdmin}
      permissions={permissions.manage.users}
    />
    <PrivateRoute
      exact
      path={APP_ROUTES.manage.managers}
      component={ManageableList}
      permissions={permissions.manage.managers}
    />
    <PrivateRoute
      exact
      path={APP_ROUTES.manage.manageable_list_edit}
      component={ManageableItemEdit}
      permissions={permissions.manage.managers}
    />
    <PrivateRoute
      exact
      path={APP_ROUTES.manage.manageable_list_create}
      component={ManageableItemCreate}
      permissions={permissions.manage.managers}
    />
    <PrivateRoute
      exact
      path={APP_ROUTES.manage.manageable_list_add_user}
      component={ManageableListAddUser}
      permissions={permissions.manage.managers}
    />
    <PrivateRoute
      path={APP_ROUTES.reports.affiliates_report}
      component={AffiliatesReport}
      permissions={permissions.reports}
    />
    <PrivateRoute
      path={APP_ROUTES.reports.affiliates_geo_report}
      component={AffiliatesGeoReport}
      permissions={permissions.reports}
    />
    <PrivateRoute
      path={APP_ROUTES.reports.affiliates_difference_report}
      component={AffiliateDifferenceReport}
      permissions={permissions.reports}
    />
    <PrivateRoute
      path={APP_ROUTES.reports.gamers_ftd_report}
      component={GamersFTDReport}
      permissions={permissions.reports}
    />
    <PrivateRoute
      path={APP_ROUTES.reports.gamers_report}
      component={GamersReport}
      permissions={permissions.reports}
    />
    <PrivateRoute
      path={APP_ROUTES.reports.geo_report}
      component={GeoReport}
      permissions={permissions.reports}
    />
    <PrivateRoute
      exact
      path={APP_ROUTES.manage.users_create}
      component={UsersCreate}
      permissions={permissions.manage.users}
    />
    <PrivateRoute
      exact
      path={APP_ROUTES.manage.user_profile}
      component={UsersProfile}
      permissions={permissions.manage.users}
    />
    <PrivateRoute
      exact
      path={APP_ROUTES.login.id}
      component={LoginById}
      permissions={permissions.manage.users}
    />
    <PrivateRoute
      exact
      path={APP_ROUTES.manage.notifications.list}
      component={ManageableNotificationsList}
      permissions={permissions.manage.notifications}
    />
    <PrivateRoute
      exact
      path={APP_ROUTES.manage.notifications.create}
      component={NotificationCreate}
      permissions={permissions.manage.notifications}
    />
    <PrivateRoute
      exact
      path={APP_ROUTES.manage.notifications.edit}
      component={NotificationEdit}
      permissions={permissions.manage.notifications}
    />
    <PrivateRoute
      path={APP_ROUTES.content.translations.list}
      component={TranslationList}
      permissions={permissions.content.translation}
    />
    <PrivateRoute
      path={APP_ROUTES.content.translations.translation}
      component={TranslationEdit}
      permissions={permissions.content.translation}
    />
    <PrivateRoute
      path={APP_ROUTES.content.translations.create_key}
      component={TranslationKeyCreate}
      permissions={permissions.content.translation}
    />
    <PrivateRoute
      path={APP_ROUTES.content.translations.import_excel}
      component={TranslationImportExcel}
      permissions={permissions.content.translation}
    />
    <PrivateRoute
      path={APP_ROUTES.affiliates_programs.brands}
      exact
      component={AffiliateBrands}
      permissions={permissions.manage.brands}
    />
    <PrivateRoute
      path={APP_ROUTES.affiliates_programs.brand_edit}
      exact
      component={AffiliateBrandItemEdit}
      permissions={permissions.manage.brands}
    />
    <PrivateRoute
      path={APP_ROUTES.affiliates_programs.landing_edit}
      exact
      component={AffiliateLanding}
      permissions={permissions.manage.brands}
    />
    <PrivateRoute
      path={APP_ROUTES.affiliates_programs.landing_create}
      exact
      component={AffiliateLandingCreate}
      permissions={permissions.manage.brands}
    />
    <PrivateRoute
      path={APP_ROUTES.affiliates_programs.program_edit}
      exact
      component={AffiliateProgramEdit}
      permissions={permissions.manage.brands}
    />
    <PrivateRoute
      path={APP_ROUTES.affiliates_programs.program_create}
      exact
      component={AffiliateProgramCreate}
      permissions={permissions.manage.brands}
    />
    <PrivateRoute
      path={APP_ROUTES.chat.inner}
      exact
      component={ChatInnerPage}
    />
    <PrivateRoute
      path={APP_ROUTES.chat.create}
      exact
      component={ChatCreateForm}
    />
    <PrivateRoute path={APP_ROUTES.chat.list} exact component={ChatsListUser} />
    <PrivateRoute
      path={APP_ROUTES.chat.admin.inner}
      exact
      component={ChatInnerPageAdmin}
      permissions={permissions.chat.admin}
    />

    <PrivateRoute
      path={APP_ROUTES.chat.admin.list}
      exact
      component={ChatsListAdmin}
      permissions={permissions.chat.admin}
    />
    <PrivateRoute
      path={APP_ROUTES.chat.admin.create}
      exact
      component={ChatCreateFormAdmin}
      permissions={permissions.chat.admin}
    />
    <PrivateRoute
      path={APP_ROUTES.content.testimonials.list}
      exact
      component={TestimonialList}
      permissions={permissions.content.testimonials}
    />
    <PrivateRoute
      exact
      path={APP_ROUTES.content.testimonials.create}
      component={TestimonialCreate}
      permissions={permissions.content.testimonials}
    />
    <PrivateRoute
      exact
      path={APP_ROUTES.content.testimonials.edit}
      component={TestimonialEdit}
      permissions={permissions.content.testimonials}
    />
    <Route path={APP_ROUTES.faq} component={FAQ} exact />
    <PrivateRoute
      path={APP_ROUTES.content.faq.list}
      exact
      component={FaqList}
      permissions={permissions.content.faq}
    />
    <PrivateRoute
      path={APP_ROUTES.content.faq.edit}
      exact
      component={FaqEdit}
      permissions={permissions.content.faq}
    />
    <PrivateRoute
      path={APP_ROUTES.content.faq.create}
      exact
      component={FaqCreate}
      permissions={permissions.content.faq}
    />
    <PrivateRoute
      path={APP_ROUTES.manage.fraudsters.list}
      exact
      component={FrodersList}
      permissions={permissions.manage.fraudsters}
    />
    <PrivateRoute
      path={APP_ROUTES.manage.fraudsters.edit}
      exact
      component={FroderEdit}
      permissions={permissions.manage.fraudsters}
    />
    <PrivateRoute
      path={APP_ROUTES.manage.fraudsters.create}
      exact
      component={FroderBulk}
      permissions={permissions.manage.fraudsters}
    />
    <Route exact path="/" component={HomePage} />
    <Route component={Page404} />
  </Switch>
);
