import React from "react";
import { AuthTemplate } from "../../../modules/Layouts";
import { ActivateUser } from "./components";
import { useLoginByHash } from "./api";
import { useUrlQuery } from "./utils";

const LoginByHash = () => {
  const query = useUrlQuery();
  const hash = query.get("hash");

  return (
    <AuthTemplate>
      <ActivateUser activateHook={useLoginByHash} userData={hash} />
    </AuthTemplate>
  );
};

export default LoginByHash;
