import axiosClient from "../../../../system/helpers/axiosConfig";
import {
  GetTournamentsRequest,
  GetTournamentsShowRequest,
  TournamentsResponse,
  TournamentsShowResponse,
} from "../utils";

export const getTournaments = (params: GetTournamentsRequest) => {
  return axiosClient
    .get<TournamentsResponse>(`/api/tournaments`, { params })
    .then((r) => r.data);
};

export const getTournamentsShow = (params: GetTournamentsShowRequest) => {
  return axiosClient
    .get<TournamentsShowResponse>(`/api/tournaments/show`, { params })
    .then((r) => r.data);
};
