import React from "react";

const AuthMailIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.636 5.54504H19.363C20.267 5.54504 21 6.27804 21 7.18204V17.818C21 18.722 20.267 19.454 19.364 19.454H4.636C3.733 19.455 3 18.722 3 17.818V7.18204C3 6.27804 3.733 5.54504 4.636 5.54504Z"
        stroke="#828282"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.11914 6.57504L10.8131 12.078C11.5081 12.575 12.4421 12.576 13.1381 12.08L20.8761 6.56104"
        stroke="#828282"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default AuthMailIcon;
