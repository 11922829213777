import React from "react";
import { Link } from "react-router-dom";
import { APP_ROUTES } from "../../../../system/constants/constantsUrl";
import { _t } from "../../../../modules/Common/components/InjectIntlContext";

interface BeveragesBannerProps {
  bannerIdx: number;
}

const BeveragesBanner: React.FC<BeveragesBannerProps> = (props) => {
  const { bannerIdx } = props;
  return (
    <div className={`beverages-banner beverages-banner--${bannerIdx}`}>
      <div className="beverages-banner__info">
        <h2 className="beverages-banner__title">{_t("follow_title")}</h2>
        <p className="beverages-banner__txt">
          {_t("follow_text")}
        </p>
        <a
          href="https://income.customer.smartsender.eu/lp/NtNp9xm1"
          target="_blank"
          rel="noreferrer"
          className="income-button beverages-banner__btn"
        >
          {_t("get started")}
        </a>
      </div>
      <div className="beverages-banner__img" />
    </div>
  );
};

export default BeveragesBanner;
